// 按鈕Hover的狀態
%btnMainHover{
    background-color: $colorMain;
    border-color: $colorMain;
    color: #fff;
    img{
        content:url('/assets/images/icon-link-small-active.png');
    }
}

%btnMainHover,
%btnoutlineHover{
    background-color: $colorMain;
    border-color: $colorMain;
    color: #fff;
}

// 按鈕樣式
.btn{
    @include fontSize(18px);
    line-height: 1.5;
    font-weight: 100;
    @include radius(0px);
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 0.5em;
    .fa{
        font-size: 0.6em;
        font-weight: 100;
        vertical-align: middle;
        margin-top: -3px;
        margin-left: 6px;
    }

    .icon {
        vertical-align: top;
        margin-top: -2px;
        margin-right: 3px;
    }

    &-dark{
        &:focus{
            color: #fff !important;
        }
    }

    // 從無框有背景延伸
    &-main{
        border:1px solid $colorMain;
        background-color: $colorMain;
        color:#fff;

        &-normal{
            border:1px solid $colorMain;
            background-color: $colorMain;
            color:#fff;
            padding: 0.75em 1.5em;
            &:hover,
            &:focus{
                background-color: #fff !important;
                color: $colorMain !important;
            }
        }

        &-default{
            background-color: #d8d6d6;
            border:#d8d6d6 2px solid;
            &:hover{
                background-color: $colorBase;
                color: #d8d6d6;
            }
        }
    }

    // 從有框無背景延伸
    &-outline{
        &-1{
            border-style: solid !important;
            border-width: 1px !important;
        }

        &-main{
            border:2px solid $colorMain;
            color:$colorMain;
            &:hover,
            &:focus{
                background-color: $colorMain !important;
                color: #fff !important;
            }
        }

        &-default{
            @include fontSize(18px);
            border:#959595 1px solid;
            padding: 0.45em 1.25em !important;
            line-height: 1.5;
            &:hover{
                @extend %btnMainHover;
            }
            
            // 無背景/有框
            &.main{
                border-color: $colorMain;
                color: $colorMain;
                &:hover{
                    @extend %btnMainHover;
                }
            }
            
            // 有背景/框
            &.mainAll{
                border-color: $colorMain;
                background-color: $colorMain;
                color:#fff;
                &:hover{
                    @extend %btnMainHover;
                }
            }

            // 有背景/無框
            &-main{
                background-color: $colorMain;
                color:#fff;
                &:hover{
                    @extend %btnMainHover;
                }
            }
        }

        &-white{
            color:#fff;
            border:#fff 1px solid;
            padding: 0.35em 1.25em !important;
            line-height: 1.5;
            &:hover{
                @extend %btnMainHover;
    
                background-color: #fff;
                color:$colorMain;
            }
        }
    }

    &-big{
        padding: 1.15rem 3em !important;
    }

    &-bigx{
        &f18{
            font-size: get-vw-mobile(18px);
            padding: 0.4em 2em 0.45em !important;
            @include setMinRwd{
                @include fontSize(18px);
            }
        }
        &f24{
            font-size: get-vw-mobile(24px);
            padding: 0.4em 1.65em 0.45em !important;
            @include setMinRwd{
                @include fontSize(24px);
            }
        }
    }

    &-noRadius{
        border-radius: 0;
    }

    // 包按鈕的框
    &-wrap {
        @extend %setFlex-center;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        margin: 2rem 0 1rem;
        &>li {
            flex: 1;
            &+li {
                margin-left: 1.5rem;
            }
        }
        @include pad1080 {
            margin-top: 1rem;
            &>li {
                &+li {
                    margin-left: 0.5rem;
                }
            }
        }
    }
}

.js-toggle-btn{
    .btn{
        .icon{
            margin-top: 0;
        }
    }
}