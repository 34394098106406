footer {
  color:#fff;
  .footer {
  
    &-top,
    &-bottom{
      display: flex;
    }

    &-top{
      background-color: #000;
      padding-top: 40px;
      padding-bottom: 40px;

      @include setWidthRwd(1101px,1600px){
        padding: 1em 2em;
      }

      @include setMaxRwd{
        padding: 1em 0.25em;
      }
    }

    &-bottom{
      align-items: center;
      justify-content: center;
      background-color: #4b4a4a;
      @include fontSize(14px);
      font-weight: 100;
      line-height: 1.5;
      color: #fff;
      padding-top: 1em;
      padding-bottom: 1em;
    }

    &-info{
      font-size: 0.875em;
      font-weight: 100;

      @include setMaxRwd{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        span{
          padding-right: 10px;
        }
      }

      &-bottom{
        @include setMaxRwd{
          width: 100%;
          margin-top: 1.5em !important;
          .footer-icon,
          .copyright p{
            justify-content: center;
          }

          .footer-info,
          .copyright{
            flex-direction: column;
          }
        }
      }

      &-wrap{
        @extend %setFlex-center;
        width: 100%;
  
        @include setMaxRwd{
          flex: auto;
          width: 100%;
          padding-left: 0.5em;
          padding-right: 0.5em;
          li{
            &+li{
              margin-top: 0.5em;
            }
          }
        }
      }

      &-list{
        flex: 1;
        .bi{
          color: #aaaaaa;
          margin-right: 10px;
        }
      }

      &-location{
        max-width: 500px;
        display: flex;
        justify-content: space-between;
        @include mobile{
          flex-direction: column;
        }
      }
    }

    // 項目
    &-title{
      margin-bottom: 0.5em;
      h6{
        @include fontSize(24px);
      }
    }

    &-icon{
      @extend %setFlex-center;
      justify-content: flex-end;
      margin-bottom: 0.5em;
      &Link{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.5em;
        @include setSize(60px,60px);
        @include radius(50%);
        background: #fff;
        overflow: hidden;
        .svg{
          @include setSize(40px,40px);
        }
      }
    }

    &-link{
      a{
        display: block;
        font-size: 0.875em;
        margin-bottom: 0.5em;
      }
      @include setMaxRwd{
        display: flex;
        justify-content: space-between;
      }
    }

    &-txt{
      color:#d7e7f2;
    }

    // grid
    &-logo {
      display: flex;
      align-items: center;
      padding-right: 50px;
      img {
          display: block;
          max-width: 100%;
      }
      @include setMaxRwd{
        padding-right: 1.5em;
      }
    }
  }

  @include setMaxRwd{
    margin-bottom: 2.2em;
  }
}

// 給予區塊的左右側線條使用
.divider {
  display: inline-block;
  width: 1px;
  height: 15px;
  background: #bfb1a5;
  vertical-align: middle;
  margin: 0 10px;
  @include setMaxRwd{
    display: none;
  }
}

.iware {
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  opacity: 0.5;
  padding: 5px 10px;
  &,
  a{
    color:white;
  }

  a {
      &:hover {
          text-decoration: underline;
      }
  }

  b{
    font-weight: 100;
  }
}

// Gotop 使用
.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
  .link-btn {
    a {
      &:hover {
          opacity: 0.65;
      }
    }

    &.nabar-icon-mobile{
      @include setMinRwd{
        display: none;
      }
      img{
        max-width: 100%;
      }
    }

    &.top{
      @include setMaxRwd{
        display: none;
      }
    }
  }

  &.active {
      right: 5px;
  }

  @include setStyleRwd(1600px){
    &.active {
        right: 10px;
    }
  }

  @include setMaxRwd{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: #000;
    padding: 5px;
    margin: 0;
    .link-btn {
      text-align: center;
      margin: 0;

      &,
      .link-btn-toggle{
        display: block;
        width: 100%;
      }

      .icon{
        small{
          font-size: 100%;
        }
      }
    }
  }
}

// Cookie Privacy
.cookies {
  display: none;
  width: 100%;
  position: fixed;
  background: white;
  border-top: 1px solid #aeadad;
  color: #333;
  @include fontSize(15px);
  font-size: 0.9375rem;
  text-align: center;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: .5rem 1rem;
  
  & > p {
    font-weight: 400;
    line-height: 2;
    a {
      text-decoration: underline !important;
      color: blue;
      font-weight: 500;
    }
    .btn {
      color:#fff;
      display: inline;
      @include fontSize(13px);
      text-decoration: none !important;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }
  }
  
  &.display {
    display: block;
    animation: cookies 1s 1;
  }

  @include pad {
    width: calc(100% - 1em);
    margin: .5em;
    border-top: none;
    @include fontSize(14px);
  }

  &-close {
    transition: all 0.2s ease-out;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}