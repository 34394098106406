$aniTrans : all 0.3s ease-out;

.index{

    // LOGO裝飾品
    &-titleLogo{
        margin-top: 0;
        margin-bottom: 3.5em;
        .img{
            position: absolute;
        }

        @include setMinRwd{
            .img{
                right: 1em
            }
        }

        @include setMaxRwd{
            margin-top: 1.5em;
            margin-bottom: 6em;
        }
    }

    // 內框
    &-content{
        @include setBackSet(top,cover);
        background-image: url('../images/bg_style.jpg');
        margin-top: 4.2em;
        padding-bottom: 2em;

        &-list{
            height: 100%;
        }

        @include setWidthRwd(1001px,1500px){
            padding-left: 1em;
            padding-right: 1em;
        }

        @include setMaxRwd{
            .default-card-wrap.card-wrap {
                margin-bottom: -3em;
            }
        }
    }

    // 頁面使用
    &-page{}
}

@mixin circleLineShort {
    content: "";
    display: block;
    width: 88px;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

@mixin circleLineLong {
    content: "";
    display: block;
    width: 100%;
    max-width: 31.25em;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

// 成果的圓圈圈
.circle {
    width: 100%;
    padding-top: calc(100% + 1px);
    position: relative;
    border-radius: 50%;
    pointer-events: none;
    overflow: visible;
    *{
        position: relative;
        z-index: 2;
        color:#fff !important
    }

    &::after {
        content: '';
        display: block;
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        border:#fff 1px solid;
        border-radius: 50%;
        position: absolute;
        left: 25px;
        top: 25px;
        z-index: 1;
        @include setMaxRwd{
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            left: 10px;
            top: 10px;
        }
    }

    &Txt{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        
        @include setFlexStyle(center,center);
        flex-direction: column;

        line-height: 0.875;
        transform: translate(-50%,-50%);
        .number {
            font-size: get-vw(85px);
            font-family: $Roboto;
        }
        .main {
            font-size: get-vw(22px);
            line-height: 1.25;
        }

        .title{
            font-size: get-vw(24px);
            font-weight: 900;
            color:#020202 !important
        }

        .txt{
            font-size: get-vw(22px);
            font-weight: 900;
            color:#a8a8a8 !important
        }

        @include setMaxRwd{
            p{
                margin-bottom: 0.5em;
                line-height: 0.75;
            }
            .number {
                font-size: get-vw-mobile(70px);
                margin-bottom: 0.25em;
            }
            .main {
                font-size: get-vw-mobile(22px);
            }
    
            .title{
                font-size: get-vw-mobile(20px);
            }
    
            .txt{
                font-size: get-vw-mobile(18px);
            }
        }
    }

}

.tech_platform_list{
    position: relative;
    max-width: 100%;
    @include setFlexStyle(flex-start,center);
    @include setMaxRwd{
        flex-wrap: wrap;
    }

    li {
        width: 100%;
        max-width: 365px;
        position: relative;
        list-style: none;
        margin-left: 0.938em;
        margin-right: 0.938em;

        .circle{
            &::before {
                @include circleLineShort;
                right: -3.15em;
                
                @include setWidthRwd(1100px,1500px){
                    width: 85px;
                    left: -3.25em;
                }
            }
        }

        &:first-child{
            &::after {
                @include circleLineShort;
                left: -3.15em;
                @include setWidthRwd(1100px,1500px){
                    left: -3.5em;
                }
            }
            &::before{
                @include circleLineLong;
                left: -19em
            }
        }

        &:last-child{
            &::before{
                @include circleLineLong;
                right: -21em;
                @include setWidthRwd(1100px,1500px){
                    right: -18.85em;
                }
            }
        }

        @include setMaxRwd{
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 1rem;
        }

        @include pad{
            width: 50%;

            &:first-child,
            &:last-child{
                &::before,
                &::after {
                    display: none;
                }
            }
            .circle{
                &::before {
                    display: none;
                }
            }
        }
    }

    // 顏色框
    &.tech-border{
        .circle {
            &::after{
                border-color: #000;
                background-color: #fff;
            }

            *{
                color:#000 !important;
            }
        }
        li{
            max-width: 350px;
            margin-left: 0;
            margin-right: 0;
            
            &,
            .circle {
                &::before{
                    display: none;
                }
            }
            &::after{
                display: none;
            }

            &:nth-child(odd){
                .circle {
                    &::after{
                        border-color: $colorMain;
                    }
                    *{
                        color:$colorMain !important;
                    }
                }
            }
        }
    }
}
  
.animationItem {
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
    transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}
  
.animationItem.animated {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}