.about{
    &Box{
        @extend %setFlex;
    }

    &Txt{
        @include fontSize(18px);
        color: #1b1b1b;
        line-height: 1.85;

        @include setMinRwd{
            @include fontSize(24px);
        }
    }

    &-intro{
        // 介紹
        &-txt,
        &-img{ width: 100%; }

        &-txt{
            padding-bottom: 1em;
            padding-left: 1em;
            padding-right: 1em;
        }

        @include setMinRwd{
            margin-top: 1em;
            &-txt{
                @include setBackSet(top,100%);
                background-image: url('../images/about-introTxt-img.png');
                padding-left: 55px;
                padding-top: 150px;
                padding-bottom: 160px;
                padding-right: 200px;

                flex: 1;
                margin-right: -8em;
            }

            &-img{
                flex: 0 0 850px;
                max-width: 850px;
            }
        }

        @include setWidthRwd(1390px,1500px){
            &-txt{
                background-size: 800px;
                padding-top: 70px;
                padding-right: 160px;
            }

            &-img {
                flex: 0 0 745px;
                max-width: 745px;
            }
        }

        @include setWidthRwd(1001px,1350px){
            &-txt{
                padding-top: 2em;
                margin-right: -5em;
                padding-right: 6em;
                padding-bottom: 0;
            } 

            &-img{
                flex: 0 0 50%;
                max-width: 50%;
            } 
        }


        // 時間軸
        &-timeline{
           padding-bottom: 1.5em;
           overflow: hidden; 
            &::after{
                content: '';
                display: block;
                @include setSize(20px,21px);
                @include setBackSet(center,contain);
                background-image: url('../images/timeLine-arrow-down.png');
                position: absolute;
                left: 50%;
                bottom: 0px;
                margin-left: -139px;
            }

            @include pad{           
               &::after{
                margin-left: -4.85em;
               }
            }
        }

        // 底部說明
        &Sub{
            box-shadow: 0 0 20px 15px rgba(0, 0, 0, 0.1) !important;
            background-color: #fff;
            margin-top: 2.75em;
            padding: 1.5em;
            &-img{
                margin-right: 1em;
            }
            &-txt{
                padding-top: 0.75em;
                flex: 1;
            }

            @include setMinRwd{
                margin-bottom: 1.75em;
                padding: 3.25em 2.75em;

                &-img{
                    margin-right: 30px;
                }
                
                &-txt{
                    padding-top: 6px
                }
            }

            @include setMaxRwd{
                &-img{
                    max-width: 2em;
                }

                &-txt {
                    padding-top: 0.5em;
                }
            }
        }
    }
}

// 時間軸
.timeline {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        display: flex;
        align-items: stretch;
        color: #1b1b1b;
        padding-bottom: 2.5em;
    }

    &-date {
        width: 100px;
        @include fontSize(32px);
        font-weight: bold;
        text-align: center;
        line-height: 1.5;
        font-family: $Roboto;
        font-weight: 900;
        margin-right: 25px;

        @include pad{
            width: auto;
            @include fontSize(25px);
            margin-top: 4px;
            margin-right: 20px;
        }
    }

    &-line{
        flex-basis:260px;
        height: 1px;
        background-color: #7d7d7d;
        margin-top: 23px;
        &::before{
          content: '';
          display: block;
          @include setSize(8px,8px);
          @include radius(50%);
          background-color: #7d7d7d;
          position: absolute;
          left: -8px;
          top: -3px;
        }

        @include pad{
            flex-basis: 2em;
            width: 100%;
        }
    }

    &-content {
        flex: 1;
        padding-left: 50px;
        &::before{
            content: '';
            display: block;
            @include setSize(22px,22px);
            @include radius(50%);
            background-color: #fff;
            border:#000 3px solid;
            position: absolute;
            left: 0;
            top: 12px;
            z-index: 1;
        }

        &::after{
            content: '';
            display: block;
            @include setSize(1px,calc(100% + 2.5em));
            background-color: #d2d2d2;
            position: absolute;
            top: 15px;
            left: 10px;
            z-index: 0;
        }

        h2 {
            font-size: 20px;
            margin-bottom: 10px;
        }
        
        p {
            @include fontSize(18px);
            margin-bottom: 0;
        }
        
        @include setMinRwd{
            p {
                line-height: 2.5; 
            }
        }

        @include pad{
            padding-left: 2em;
            padding-top: 0.5em;
        }
    }
}