.form{
    .form-control{
        @include radius(0);
        &,
        &:hover,
        &:focus{
            border-width:0 0 1px 0;
            border-style: solid;
            outline: none;
            box-shadow: none;
            background-color: #fff;
        }

        &:hover,
        &:focus{
            border-color: #000000;
        }
    }

    &-input-radio{
        height: 50px;
    }

    .radio_style{
        margin-top: 0 !important;
    }

    legend{
        line-height:1.5;
        @include fontSize(16px);
        margin-bottom: 1rem;
    }

    label{
        margin-bottom: 0;
    }

    &-flex{
        display: flex;
        margin-bottom: 1.5em;
        &>label{
            flex: none;
            width: 80px;
            color: #696969;
        }
    }

    &-login{
        display: flex;
        margin-bottom: 3em;

        .form-group{
            &-sign{
                margin-left: 15px;
                @include mobile{
                    flex: none;
                }
            }
        }

        .btn{
            height: 100%;
            @include mobile{
                padding-left: 1em;
                padding-right: 1em;
            }
        }
    }

    &-check {
        @extend %setFlex-center;
        padding-left: 0;

        &-input[type="checkbox"] {
            margin-top: 4px;
            margin-left: 0;
            margin-right: 10px;
        }

        &Login{
            @include fontSize(14px);
            color: #777777;
        }
    }

    .divider{
        display: inline-block;
    }
}

// 
.formTxt-arial{
    font-family: Arial;
}

.formTxt-roboto{
    font-family: $Roboto;
}

.formTxt-bg{
    background: #f6f6f6;
}
