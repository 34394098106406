// 聯絡我們
.contact{
    &-block {
        position: relative;
        @extend %setFlex;
    }

    &-form {
        border: #b5b5b5 1px solid;
        background-color: #fff;
        padding: 1em;

        .btn-wrap{
            margin-top: 2em;
            li{
                display: flex;
                justify-content: center;
                width:100%;
            }

            .btn {
                line-height: 2;
            }

            @include mobile{
                flex-direction: column;
                li{
                    margin-bottom: 10px;
                }
            }
        }

        @include setMinRwd{
            padding: 50px 60px 35px;
            max-width: 62.5%;
            legend{
                margin-bottom: 2.75rem;
            }
        }
        
        @include pad1024{
            margin-bottom: 1.5rem;
            max-width: 100%;
        }
    }

    &-info{
        .icon{
            margin-right: 20px;
        }

        .txt{
            @extend %setFlex;
            width: 100%;
            @include fontSize(22px);

            &-item{
                color: #484747;
                padding-right: 10px;
            }

            p{
                margin-bottom: 0;
            }
            
            @include pad1024{
                @include fontSize(18px);
                margin-top: 1rem;
            }
        }

        &-list{
            display: flex;
            border-bottom: 1px solid #b2b1b1;
            padding-bottom: 1.25em;
            & ~ li{
                margin-top: 1.5em;
            }

            @include setMinRwd{
                align-items: center;
            }
        }

        @include setMinRwd{
            flex: 1;
            padding-left: 80px;
        }
    }
    
    @include setMaxRwd{
        display: block;
    }
}