// -------- BS -------- //
.form-control,
.btn{
    border-radius: 0.5em;
}

.form-control{
    height: 50px;
}

.modal {
    text-align: center;
    padding-right: 0 !important;

    // &-content{
    //     border-color: #accccd;
    //     border-width: 3px;
    // }

    &-open{
        padding-right: 0 !important;
    }

    @include setMinRwd{
        text-align: center;
        padding: 0!important;
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -4px;
        }

        &-dialog {
            display: inline-block;
            text-align: left;
            vertical-align: middle;
        }
    }

    @include  setStyleMinRwd(500px){
        &-dialog {
            width: 100%;
            max-width: 44rem;
        }
    }

    @include setMaxRwd{
        text-align: center;
        padding: 0!important;

        &.show{
            display: flex !important;
            align-items: center;
        }
          
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -4px;
        }
          
        .modal-dialog {
            display: inline-block;
            text-align: left;
            vertical-align: middle;
        }
    }

    // @include setWidthRwd(1100px,1500px){
    //     &-dialog {
    //         max-width: 70rem;
    //     }
    // }

    &-autoheight{
        .modal-body {
            position: relative;
            overflow-y: auto;
            overflow-x: hidden;
            min-height: 100px !important;
            max-height: 100vh !important;
            @include setStyleMinRwd(500px){
                max-height: calc(100vh - 50px) !important
            }
            @include pad1080{
                height: calc(90vh - 1.5rem) !important;
            }
        }
        @include pad1080{
            transform: translateY(5%);
        }
    }

    // 會員用
    &-member{
        .modal-header,
        .modal-footer{
            border-top: none;
            border-bottom: none
        }

        .modal-header{
            position: static;
            margin-bottom: 1em;
        }

        .modal-content{
            @include radius(0);
            @include setMinRwd{
                padding: 4rem;
            }
        }

        .modal-footer{
            .btn{
                flex: 1;
                @include fontSize(14px);
                display: inline-flex;
                justify-content: center;
                align-items: center;
                position: relative;
                padding: 0.75em 2em;

                &-regis{
                    @include fontSize(18px);
                }

                @include mobile{
                    padding-left: 2.25em;
                    padding-right: 2.25em;
                }
            }

            .icon{
                position: relative;
                flex: none;
                &-fb,
                &-user{
                    top: 2px;
                    left: -10px;
                }

                &-right{
                    right: -14px;
                }
            }
        }
        
        .btn-close{
            position: absolute;
            right: 1.25em;
            top: 1.25em;
            @include fontSize(24px);
        }
    }
}

.js-toggle-btn{
    .btn{
        @include fontSize(16px);
        @include radius(20px);
        border: #ddd 1px solid;
        i{
            color: #7d7d7d;
        }
    }
}