// 產品詳細頁
.Prod{
    &-conInfo{
        @extend %setFlex;
        padding-bottom: 1.5em;
        @include setMinRwd{
            align-items: flex-start;
            padding-top: 3em;
            padding-bottom: 3em;
            margin-top: 1em;
        }

        &-img{
            overflow: hidden;
            @include setMinRwd{
                flex-basis: 855px;
                margin-left: -1.5rem !important;
            }

            @include setWidthRwd(1101px,1600px){
                flex-basis: 680px;
            }

            @include setMaxRwd{
                padding-top: 0.5em;
                padding-left: 1.75em;
                padding-right: 1.75em;
            }
            
            .swiper {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                &-slide {
                    background-size: cover;
                    background-position: center;
                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &-button-next,
                &-button-prev {
                    width: 50px;
                    color: $colorBack;
                    &::after{
                        @extend .fw-900;
                        @include setSize(50px,50px);
                        @include radius(50%);
                        background: $colorBlack;
                        @include fontSize(18px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                @include setMaxRwd{
                    &-button-next{
                        right: 0;
                    }

                    &-button-prev{
                        left: 0;
                    }
                }
            }
            
            // 大圖
            .Prod_mySwiper2 {
                height: 80%;
                width: 100%;
                margin-bottom: 0.25em;
                @include setMinRwd{
                    margin-bottom: 15px;
                }
            }
            
            // 縮略圖
            .Prod_mySwiper {
                height: 20%;
                box-sizing: border-box;
                padding: 10px 0;
                .swiper-slide {
                    width: 25%;
                    height: 100%;
                    opacity: 0.4;
                    &-thumb-active {
                        opacity: 1;
                    }
                
                    @include setMaxRwd{
                        img{
                            height: 150px;
                        }
                    }
                
                    @include mobile{
                        img{
                            height: 70px;
                        }
                    }
                }
            }
        }

        &-info{
            padding-left: 1.75em;
            padding-right: 1.75em;
            flex: 1;
            @include setMinRwd{
                padding:2.75em 0 0 3em;
            }
        }

        // 產品詳細頁的聯絡資訊
        &-contact{
            border:4px solid #dfdfdf;

            &Title{
                background-color: #dfdfdf;
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                .icon{
                    margin-right: 10px;
                }

                @include setMinRwd{
                    @include fontSize(20px);
                    padding-top: 1.2em;
                    padding-bottom: 1.2em;
                }
            }

            &Title,
            &List{
                padding-left: 1.5em;
                padding-right: 1.5em;

                @include setMaxRwd{
                    padding-left: 0.5em;
                    padding-right: 0.5em;
                }
            }
    
            &List{
                background-color: #fff;
                padding-top: 1.5em;
                padding-bottom: 1.5em;
                @include setMaxRwd{
                    padding-top: 0.5em;
                    padding-bottom: 0.5em;
                }

                li{
                    @extend %setFlex-center;
                    &~li{
                        border-top: #e4e5eb 1px solid;
                        margin-top: 12px;
                        padding-top: 14px;
                    }
                }

                &-name{
                    .tag-item{
                        @include fontSize(16px);
                        padding: 10px;
                    }
                    margin-right: 10px;
                }

                @include setMinRwd{
                    &-name{
                        .tag-item{
                            @include fontSize(20px);
                        }
                    }

                    &-tel{
                        @include fontSize(30px); 
                    }
                }
            }

            @include setMinRwd{
                margin-top: 3em;
            }
        }
    }

    &-item{
        color: #585858;
        @include setMinRwd{
            @include fontSize(20px);
        }
    }

    &-heading{
        line-height: 1.5;
        @include setMinRwd{
            @include fontSize(36px);
        }
    }

    &-unreset{
        border-top: 1px dotted #898989;
        border-bottom: 1px dotted #898989;
        padding-top: 1em;
        padding-bottom: 0.5em;
        margin-top: 1em;
        margin-bottom: 1em;
        dl{
            @extend %setFlex;
            margin-bottom: 0;

            dt{
                width: 185px;
                font-weight: normal;
                padding-left: 1.2em;
                padding-right: 1.2em;
            }
            
            dd{
                flex: 1;
                color:#707070;

                @include setMinRwd{
                    @include fontSize(24px);
                }
            }
        }

        @include pad{
            padding-top: 0.5em;
            padding-bottom: 0em;
            margin-bottom: 1.5em;
            @include pad{
                dl{
                    flex-direction: column;
                    margin-bottom: 0.25em;
                    dt,
                    dd{
                        padding-left: 0.2em;
                    }
                }
            }
        }
    }

    &-conTxt{}

    &-photo-small{
        padding-left: 1.5em;
        padding-right: 1.5em;
        @include setMinRwd{
            padding-left: 3em;
            padding-right: 3em;
            margin-top: 15px;
        }
    }
}

$tableColor-colors: (main: #e9edf4, secondary : #eeeeee);

.item{
    &-table{
        @include radius(10px);
        border: #6b6b6b 1px solid;
        background-color: #fff;
        padding: 6px 10px 3px;
        table{
            border: none;
            &>:not(caption) > * > * {
                background-color: #fff;
                padding: 0.75rem 1rem;
            }
    
            thead{
                th{
                    @include fontSize(18px);
                    color: #475c86;
                    border-bottom-width: 0 !important;
                }
            }
    
            tbody{
                td{
                    @include fontSize(20px);
                    border-width: 1px;
                    border-color: #b4c0d6;
                    padding-top: 0.35rem;
                    padding-bottom: 0.35rem;
                }

                tr{
                    td{
                        &:first-child{
                            border-left-width: 0;
                        }
                        &:last-child{
                            border-right-width: 0;
                        }
                    }
                }

                &:nth-child(even){
                    td{
                        background-color: map-get($tableColor-colors , main);
                    }
                }

                &:nth-child(odd){

                    &::before,
                    &::after {
                        content: ".";
                        display: block;
                        line-height: 1em;
                        color: white;
                    }

                    td{
                        background-color: map-get($tableColor-colors , secondary);
                    }
                }
            }
        }

        @include setMaxRwd{
            width: 1140px;
            margin-bottom: 1em;
        }
    }
}
      
.table{
    &-main{
        tbody{
            td{
                background-color: map-get($tableColor-colors , main) !important;
            }
        }
    }

    &-secondary{
        tbody{
            td{
                background-color: map-get($tableColor-colors , secondary) !important;
            }
        }
    }
}