.equip{
    &_wrap{
        margin-bottom: 3.5em;
        @include setMinRwd{
            padding-left: 0.75em;
            padding-right: 0.75em;
        }
    }

    &_num{
        @include fontSize(24px);
        color: #1b1b1b;
        font-family: $OpenSans-300;
        font-weight: 900 !important;
        text-align: left;
        @extend .styleLine-under;
        &::before {
            @include setSize(100%,3px);
            left: 0;
            margin-left: 0;
            background-color: #1b1b1b;
        }
    }

    &_title{
        @include fontSize(20px);
        margin: 1.25em 10px;
    }

    &_info{
        background-color: #eeeeee;
        padding: 1em 1.5em;
        &_item{
            @include fontSize(16px);
            color:#797878;
        }
        &_name{
            @include fontSize(18px);
            color: #000000;
        }

        @include setMinRwd{
            display: flex;
            &_item{
                flex: none;
                padding-top: 2px;
                padding-right: 1em;
            }
        }
    }

    // 合作廠商
    &_factory{
        &_wrap{
            @include setMinRwd{
                padding-left: 1.75em;
                padding-right: 1.75em;
            }
        }

        &_head{
            @extend .styleLine-rightMiddle;
            p{
                @include fontSize(21px);
                padding-right: 0.75em;
            }
            &::after {
                height: 3px;
                background-color: #000;
            }
        }

        &_img{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            margin-left: 1.25em;
            margin-right: 0;
            margin-top: 0.75em;
            .img{
                text-align: center;
            }

            @include setMinRwd{
                .img{
                    flex: 1;
                    max-width: 178px;
                    &+.img{
                        margin-left: 15px;
                    }
                }
            }

            @include pad{
                .img{
                    &+.img{
                        margin-top: 15px;
                    }
                }
            }
        }

        &_col{
            @include setMinRwd{
                flex-basis: 175px;
            }

            @include setMaxRwd{
                width: calc(100% / 2);
                flex: none;
            }
        }

        &_row{
            &_top{
                @include setMinRwd{
                    .equip_factory_col{
                        &:not(:last-child){
                            flex-basis: calc(175px / 5);
                        }
                    }
                    .equip_factory_wrap {
                        padding-left: 2em;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}