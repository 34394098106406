// ----- 文字區塊 or 編輯器 ----- //
.article,
article {
    width: 100%;
    font-size: 100%;
    iframe {
        width: 100%;
        height: 750px;
        @include pad {
            height: 350px;
        }
    }
}

.in-box{
    overflow: hidden;
}

.js-open{
    display: block !important;
    margin-top: 5px;
}

.lh1-45{
    line-height: 1.45;
}

// For 內頁整框
.normal-content {
    overflow: hidden;
    
    @include setWidthRwd(800px,1600px){
        padding-left: 3rem;
        padding-right: 3rem;
    }

    @include setWidthRwd(800px,1100px){
        padding-top: 1em;
    }

    @include pad1024{
        padding-top: 0.5rem ;
        padding-left: 1rem;
        padding-right: 1rem;
        article{
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
}

// 有左/右內容區塊使用
.mainContent{
    padding-top: 1em;
    overflow: hidden;

    &-wrap{
        padding-bottom: 2em;
        @include setMaxRwd{
            padding-top: 1em;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    @extend %setFlex;

    &-info{
        @include setMinRwd{
            width: 315px;
            &~.mainContent-list{
                max-width: calc(100% - 315px);
                margin-left: auto;
                padding-left: 50px;
                padding-right: 1em;
            }
        }

        @include setWidthRwd(1101px,1350px){
            width: 270px;
            &~.mainContent-list{
                max-width: calc(100% - 270px);
                padding-left: 1em;
            }
        }

        @include setMaxRwd{
            .link-wrap{
                display: none;
            }
        }
    }

    &-list{
        max-width: 100%;
        flex: 1;
        padding-top: 1em;
        padding-bottom: 1em;
        @include setMinRwd{
            padding-top: 0;
        }

        @include setMaxRwd{
            margin-left: 10px;
            margin-right: 10px;
            max-width: calc(100% - 20px);
        }
    }

    @include setWidthRwd(1101px,1600px){
        padding-left: 2em;
        padding-right: 2em;
    }

    @include pad1024{
        flex-direction: column;
    }
}

// 最外框
.main{
    &-wrapper{
        max-width: 1450px;
        margin-top: 1em;
        margin-bottom: 2em;
    }
}

// 內頁標題
.headingMain-title {
    position: relative;
    width: 100%;
    line-height: 1.3;
    margin-bottom: 0.25em;
    padding: 0.65em 1em 0.65em 0.5em;
    .en{
        @include fontSize(38px);
        font-weight: 600;
        margin-bottom: 0.25rem;
        font-family: $Roboto;
        @include setMaxRwd{
            @include fontSize(20px);
        }
    }

    .big{
        @include fontSize(30px);
        @include setMaxRwd{
            @include fontSize(25px);
        }
    }

    .styleLine-under{
        margin-bottom: 0.2em;
        padding-bottom: 0.35em;
        &::before {
            width: 30px;
            bottom: 7.5px;
            margin-left: -15px;
            background-color:$colorMain;
        }
    }

    .main{
        display: inline-block;
        @include fontSize(24px);
        text-align: left;
        margin-bottom: 0;
        border: #656363 1px solid;
        @include radius(25px);
        padding: 0.2em 1em 0.2em 2em;
        @include setBackSet(15px,19px);
        background-image: url('../images/dot-title.png');
        @include setMaxRwd{
            @include fontSize(20px);
            padding-top: 0.4em;
            padding-bottom: 0.4em;
        }
    }
}

// 內頁左側連結
ul.link-wrap {
    &>li {
        &>a {
            position: relative;
            display: block;
            @include fontSize(18px);
            color: #7c7c7c;
            line-height: 1.5;
            padding: 0.75em 0rem 1.1rem 0;
            text-align: center;
            border: #c3c1c1 1px solid;
            p{
                margin-bottom: 0;
                &::first-letter{
                    @include fontSize(30px);
                    font-weight: 100;
                    padding-right: 2px;
                }
            }

            &::after {
                content: "";
                display: block;
                @include setSize(30px,4px);
                background-color: $colorSubs;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
                transition: 0.25s;
            }

            @include setMinRwd{
                background: #e9e8e8;
            }

            @include setMaxRwd{
                padding-top:0.25em;
                padding-bottom: 0.25em;
            }
        }

        &:hover,
        &.active{
            a{
                &::after{
                    opacity: 1;
                }

                @include setMinRwd{
                    background-color: #fff;
                    box-shadow: 0 0 10px rgba(0,0,0,.25);
                    color: #030303;
                    border:none;
                }
            }
        }
        
        &.active{
            a{
                @include setMaxRwd{
                    padding-bottom: 0.75em;
                }
            }
        }

        // 第二層
        &>ol{
            display: none;
            padding-top: 10px;
            &.active{
                display: block;
            }

            &>li{
                margin-bottom: 1px;
            
                &>a{
                    display: block;
                    @include fontSize(20px);
                    padding-top: 0.65rem;
                    padding-bottom: 0.65rem;
                    position: relative;
                    border-bottom: 1px solid #bfbfbf;
                    &::before{
                        content: '\f067';
                        font-family: FontAwesome;
                        color: #404040;
                        @include fontSize(13px);
                        display: block;
                        position: absolute;
                        top: 1.05rem;
                        right: 0;
                    }

                    &:hover,
                    &.active{
                        &,
                        &::before{
                            font-weight: 600;
                        }
                        
                        color: $colorMain;

                        &::before{
                            content: '\f068';
                        }
                    }
                }
            }

            // 第三層
            .second_menu{
                display: none;
                padding-top: 10px;
                padding-bottom: 15px;
                &.active{
                    display: block;
                }
                &>li{
                    padding-left: 0;
                    padding-bottom: 0;
                    &>a{
                        @include fontSize(18px);
                        color: #676767;
                        padding-top: 7px;
                        padding-bottom: 8px;
                        
                        &::before,
                        &::after {
                            display: none;
                        }

                        &:hover,
                        &:active{
                            padding-left: 15px;
                            color: $colorMain;
                        }
                    }
                }
            }
        }

        @include setMinRwd{
            padding-left: 25px;
            padding-bottom: 1em;
        }

        @include setWidthRwd(1101px,1500px){
            padding-left: 0;
        }

        @include setMaxRwd{
            &~li{
                a{
                    border-top: none;
                }
            }
        }
    }

    @include setMaxRwd{
        margin-left: 10px;
        margin-right: 10px;
    }
}

// ----- 內頁Banner ----- //
.main-banner {
    overflow: hidden;
    z-index: 1;
    img {
        width: 100%;
    }

    // 內頁Banner的文字樣式
    &-text{
        .title-main{
            justify-content: space-around;
        }
        .title-btn{
            text-align: center;
        }

        @include setMinRwd{
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &.right{
                display: flex;
                align-items: flex-end;
                justify-content: end;
                right: 5em;
                .title-main{
                    writing-mode: vertical-rl;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    font-size: 2.5em;
                    p{
                        letter-spacing: 4px;
                        margin: 0;
                    }
                    span.text-main{
                        display: inline-block;
                        line-height: 100%;
                        padding: 0.15em 0.2em 0 0;
                    }
                }

                .title-btn{
                    order: -1;
                    writing-mode: vertical-rl;
                    margin: 2em 2em 0;
                    display: inline-flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    // &::before{
                    //     content: '';
                    //     display: block;
                    //     width: 1px;
                    //     height: 100px;
                    //     background-color: #000;
                    //     margin-bottom: 1em;
                    // }
    
                    .btn{
                        @include fontSize(24px);
                        padding: 1.3em 0.55em 1em !important;
                        border-color: #000;
                        letter-spacing: 4px;
                        &:hover{
                            border-color: $colorMain;
                        }
                    }
                }
            }

            &.middle{
                .title-main{
                    line-height: 1.5;
                }
                .btn-wrap{
                    margin-top: 3em;
                }
            }
        }

        @include setMaxRwd{
            *{
                color:#000 !important;
                &::before {
                    background-color: #000 !important;
                }
            }
            .btn{
                border-color: #000 !important;
            }
        }
    }
}

.text, .img{
    &-mainRadius{
        border-radius: 15px !important;
    }
}

.ellipsis{
    @include ellipsis;
}

.border{
    &Main{
        border:$colorBase 1px solid !important
    }

    &In{
        border: 1px solid #d2d2d2;
    }

    &MainCont{
        @include fontSize(17px);
        color: #fff;
        font-weight: 600;
        line-height: 1.5;
        background-color: #6ebe77;
        @include radius(30px);
        padding: 0.5rem 1.5rem;
    }

    &None{
        border:none !important;
        &Top{
            border-top: none !important;
        }
        &Bottom{
            border-bottom: none !important;
        }
    }

    &Small{
        background-color: #fff;
        @include radius(30px);
        @extend .borderMain;
        @include fontSize(14px);
        font-weight: 600;
        line-height: 1.5;
        padding: 0.5rem 2.5rem;
    }

    &-inputMain{
        border-color: $colorMain;
    }
}

.boxShadow{
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.boxShadow_p20{
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2) !important
}

.boxShadow-main{
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

//-------- 樣式 : 字體大小 --------//
.font{
    &-lg{
        @include fontSize(52px)
    }

    &-md{
        @include fontSize(44px)
    }

    &-sm{
        @include fontSize(36px)
    }
    
    &-sms{
        @include fontSize(30px)
    }

    &-xs{
        @include fontSize(28px)
    }

    &-xsl{
        @include fontSize(26px)
    }

    &-xxs{
        @include fontSize(24px)
    }

    &-main{
        @include fontSize(22px)
    }

    &-defalut{
        @include fontSize(20px)
    }

    &-small{
        @include fontSize(18px)
    }

    &-small-xs{
        @include fontSize(16px)
    }

    &-small-xxs{
        @include fontSize(14px)
    }

    &-dot{
        @include fontSize(5px)
    }
}


// 文字粗體
.fw-900 {
    font-weight: 900 !important;
}

//-------- 文字顏色 --------//
$textColor-colors: (Main: $colorMain,Link: #1d2088,Normal: #5d5d5e, Base: $colorBase);

.text{
    &-main {
        color: map-get($textColor-colors , Main) !important;
    }

    &-base{
        color: map-get($textColor-colors , Base) !important;
    }
    
    &-underLine{
        text-decoration: underline;
    }
    
    &-link{
        color: map-get($textColor-colors , Link);
    }

    &-normal{
        color: map-get($textColor-colors , Normal);
    }
}

//-------- 頁面背景色 --------//
$bgColor-colors: (main: $colorMain, intro: #eeeae9,secondary : $bgSecondary,content: #ECECEC,help: #e0e0e0, Eee: $bgSecondary, Spec:#007ece);

.bg{
    &-main {
        background-color: map-get($bgColor-colors , main);
    }

    &-intro{
        background-color: map-get($bgColor-colors , intro);
    }

    &-second{
        background-color: map-get($bgColor-colors , secondary) !important;
    }

    &-content{
        background-color: map-get($bgColor-colors , content);
    }

    &-spec{
        background-color: map-get($bgColor-colors , Spec);
    }

    &-item{
        background-color: $colorText;
        @extend .font-xs;
        color:#fff;
        padding: 0.5em 1.25em;
        @include setMaxRwd{
            font-weight: lighter;
            font-size: 20px !important;
            padding: 0.25em 0.75em;
        }
    }

    &-contentHelp{
        background-color: map-get($bgColor-colors , help);
    }

    &-contentEee{
        background-color: map-get($bgColor-colors , Eee);
    }
}

//-------- Flex自訂 --------//
.flex{
    &-none{
        flex: none !important
    }

    &-auto{
        flex: auto !important
    }

    &-1{
        flex: 1 !important
    }
}

// -------- 標題樣式 -------- //
// 內容標題
.title{
    &-main{
        @include setFlexStyle(center,flex-start);
        @include fontSize(20px);
        font-weight: 200;
        color: #7B7B7B;
        margin-bottom: 0.75em;

        &Sec{
            @include setFlexStyle(center,flex-start);
            @include fontSize(22.5px);
            @include setMinRwd{
                @include fontSize(30px);
            }
        }

        &Heading{
            border-bottom: 1px solid #e5e5e5;
            margin-bottom: 2em;
            .title{
                position: relative;
                display: inline-block;
                color: $colorText;
                font-weight: 900;
                padding-bottom: 0.5em;
                font-size: get-vw-mobile(30px);
                @include setMinRwd{
                    @include fontSize(20px);
                }
                
                &::before {
                    content: "";
                    @include setSize(100%,3px);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    background-color: $colorMain;
                }
            }

            @include setWidthRwd(1150px,1500px){
                padding-left: 3em;
                .title{
                    @include fontSize(32px);
                }
            }

            @include setMaxRwd{
                margin-bottom: 1.5em;
            }
        }

        &HeadingPage{
            border-bottom: 1px solid #cecece;
            margin-bottom: 1.5em;
            padding-bottom: 0.75em;
            .title{
                color: $colorText;
                font-size: get-vw-mobile(30px);
            }
            .icon{
                vertical-align: top;
                margin: -1px 5px 0 7px;
            }
            @include setMinRwd{
                margin-bottom: 1.75em;
                padding-bottom: 15px;
                .title{
                    @include fontSize(24px);
                }
            }
        }
    }

    &-sub{
        font-size: get-vw-mobile(34px);
        @include setMinRwd{
            @include fontSize(36px);
        }
    }

    &-default{
        font-size: get-vw-mobile(30px);
        @include setMinRwd{
            @include fontSize(32px);
        }
    }

    &-subcontent{
        font-size: get-vw-mobile(28px);
        @include setMinRwd{
            @include fontSize(30px);
        }
    }

    &-mainHeadingBlack{
        @extend .title-mainHeading;
        padding-left: 0;
        border-bottom: none;
        .title{
            padding-bottom: 0.2em;
            &::before{
                height: 3px;
                background-color: #000;
            }
        }
    }
}

// 內容主標題
.heading-title {
    position: relative;
    width: 100%;
    @extend %setFlex-center;
    color: $colorBlack;
    line-height: 1.3;
    letter-spacing: .5px;
    margin-bottom: 30px;
    .icon{
        flex: none;
    }
    .txt{
        flex: 1;
        padding-left: 15px;
    }

    .title{
        @include fontSize(23px);
        font-weight:lighter;
        &Sub{
            @include fontSize(17px);
            margin-bottom: 2px;
        }
    }

    &.search{
        .txt{
            @extend %setFlex;
        }
        .title{
            &Sub{
                color: $colorBase;
                @include fontSize(23px);
                padding-right: 10px;
            }
        }
    }

    @include setMaxRwd{
        margin-bottom: 0.5em;
    }
}

// 樣式裝飾 : 
.style{
    &Line{
        // 右側中間線
        &-rightMiddle{
            position: relative;
            display: flex;
            align-items: center;
            *{
                flex: none;
                margin-bottom: 0;
            }
            &::after {
                content: " ";
                display: block;
                @include setSize(100%,1px);
                background-color: #e2e2e2;
            }
        }
        
        // 底線
        &-under{
            position: relative;
            text-align: center;
            padding-bottom: 0.5em;  
            &::before {
                content: "";
                @include setSize(60px,1px);
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -30px;
                background-color: $colorMain;
            }

            &.main{
                &::before {
                    @include setSize(30px,6px);
                    margin-left: -15px;
                }
            }
        }
    
        // 垂直線
        &-verticle{
            @include setFlexStyle(center,center);
            flex-direction: column;
            &::before {
                content: "";
                display: block;
                @include setSize(1px,30px);
                background-color: #000;
                margin-bottom: 1em;
            }
            &.white{
                &::before {
                    background-color: #fff;
                }
            }
        }

        // 左上線
        &-letTop{
            position: relative;
            padding-top: 0.5em;
            @include fontSize(22px);
            *{
                margin-bottom: 0;
            }
            &::after {
                content: " ";
                display: block;
                @include setSize(40px,4px);
                background-color: $colorMain;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        // 左測垂直線
        &-letVerticle{
            position: relative;
            padding-left: 15px;
            &::before {
                content: "";
                display: block;
                @include setSize(1px,20px);
                background-color: #c9c9c9;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -10px;
            }
        }
    }

    // 三角形(向右)
    &_caretRight{
        position: relative;
        padding: 10px 0 10px 30px;
        &:not(:last-child){
            border-bottom: 1px dashed #fff;
        }
        &::before{
            content:'\f0da';
            font-family: FontAwesome;
            font-size: 1.25em;
            position: absolute;
            left: 0;
            top: 0.3em;                      
        }
    }
}

// 樣式 : 特用標題
.block-title-box{
    display: flex;
    align-items: center;
    @include fontSize(24px);
    line-height: 1.25;
    margin-bottom: 1.25rem;
    padding-left: 0.5em;
    font-weight: 500;
    z-index: 2;
    .txt{
        flex: none;
        margin-bottom: 0;
    }

    &::before{
        content: '';
        display: block;
        @include setSize(15px,15px);
        background-color: #fff;
        border: $colorMain 2px solid;
        border-radius: 50%;
        position: absolute;
        top: -10px;
        left: -15px;
    }
    &::after{
        content: '';
        display: inline-block;
        @include setSize(130px,1px);
        background-color: $colorMain;
        margin-left: 0.5em;
    }
}

// 標題樣式
.titleStyle{
    display: block;
    width: 100%;
    &::before{
        content:'';
        display: block;
        @include setSize(100%,1px);
        background-color: #d6d6d6;
        position: absolute;
        top: 0px;
        z-index: 1;
    }
    &::after{
        content: '';
        display: block;
        @include setSize(30px,21px);
        
        @include setBackSet(center,contain);
        background-image: url('../images/icon_style.png');
        background-color: #fff;

        position: absolute;
        top:-10px;
        left: 50%;
        transform: translate(-50%,0);
        z-index: 2;
    }

    &.gray{
        &::after{
            background-color: #eee;
        }
    }
}

// 樣式
.style{
    // 底線
    &__underLine{
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #000;
        &__small{
            border-top: none;
            border-bottom-width:2px;
            border-bottom-style: dotted;
        }
    }

    //有序列表-圓點
    &__dot {
        position: relative;
        padding-left: 1rem;
        line-height: 1.5;
        &:not(:last-of-type){
            margin-bottom: 0.5em;
        }

        &::before {
            content: "";
            width: 7px;
            height: 7px;
            background-color: #615f5f;
            border-radius: 100%;
            position: absolute;
            left: 0;
            top: 14px;
            transform: translateY(0);
        }
    }
}

// -------- 特用連結樣式 -------- //
.content{
    
    // 連結文字
    &-item{
        position: relative;
        @extend %setFlex-center;
        justify-content: center;
        border:#dddddd 1px solid;
        @extend .text-mainRadius;
        overflow: hidden;
        transition: 0.25s;
        height: 0;
        padding: 1.65em 0.9em 46%;

        .num{
            display: inline-block;
            @include fontSize(18px);
            color:$colorText;
            line-height: 1.25;
            background-color: #eeeeee;
            text-align: center;
            padding: 10px 15px;
            border-radius: 12px;
            position: absolute;
            left: -7px;
            top: -3px;
        }

        .info{
            text-align: center;
            color:$colorText;
            @include setMaxRwd{
                padding: 1em 0.5em;
            }
        }
        
        .title{
            font-weight: 900;
            font-size: get-vw-mobile(24px);
            @include setMinRwd{
                font-size: get-vw(36px);
            }
            @include setWidthRwd(1055px,1100px){
                font-size: get-vw(33px);
            }
            @include setWidthRwd(800px,1050px){
                font-size: get-vw-mobile(15px);
            }
        }

        .txt{
            margin-bottom: 0;
            font-size: get-vw-mobile(20px);
            line-height: 1.3;
            @include setMinRwd{
                font-size: get-vw(17px);
            }
            @include setWidthRwd(1101px,1600px){
                font-size: get-vw(22px);
            }
            @include setWidthRwd(1055px,1100px){
                font-size: get-vw(25px);
            }
            @include setWidthRwd(800px,1050px){
                font-size: get-vw-mobile(16px);
            }
        }

        @include setMinRwd{
            background-color: #fff;
        }

        @include setWidthRwd(800px,1600px){
            height: auto;
            min-height: 8rem;
            padding-top: 0;
            padding-bottom: 0;
        }

        @include setMaxRwd{
            height: auto;
            border-radius: 10px;
            padding: 0 !important;
        }
    }
    
    // 連結的區塊
    &-link{
        position: relative;
        display: block;
        width: 100%;
        @include setMinRwd{
            &::before{
                content: "";
                display: block;
                @include setSize(18px,18px);
                background-repeat: no-repeat;
                background-position: top;
                background-size: contain;
                position: absolute;
                bottom: -17px;
                left: 50%;
                margin-left: -9px;
                transition: 0.25s;
                z-index: 2;
            }

            &::after{
                content: "";
                display: block;
                @include setSize(10px,10px);
                background-color: $colorMain;
                border-radius: 50%;
                position: absolute;
                left: calc(100% + 1.1em);
                top: 50%;
                margin-top: -3.5px;
                @include setMaxRwd{
                    display: none;
                }
            }
            
            &:hover,
            &.active{
                &::before{
                    background-image: url('../images/content-link-active.png');
                }

                .content-item{
                    border-color: $colorMain;
                    .title{
                        color: $colorMain;
                    }
                }
            }
        }
    }

    // 
    &-nav{
        @include setFlexStyle(flex-start,space-between);

        &-list{
            flex: 1;
            &:last-child{
                .content-link{
                    &::after{
                        display: none;
                    }
                }
            }

            @include setMinRwd{
                &+.content-nav-list{
                    padding-left: 2.5em;
                }
            }

            @include setMaxRwd{
                flex:  0 0 calc(50% - 1em);
                margin: 0 0.5em 1.5em;
            }
        }

        // 一般尺寸
        &.small{
            justify-content: center;

            .content-nav-list {
                position: relative;
                flex: none /*0 0 200px*/;
            }

            .content-link{
                &::after {
                    left: calc(100% + 1em);
                }
            }

            .content-item{
                min-height: auto;
                height: auto;
                padding: 1.15em 2em;
                .title{
                    font-size: get-vw-mobile(24px);
                    font-weight: 500;
                    @include setMinRwd{
                        font-size: get-vw(22px);
                    }
                    
                    @include setWidthRwd(1100px,1500px){
                        font-size: get-vw(28px);
                    }
                    
                    @include setWidthRwd(1100px,1300px){
                        font-size: get-vw(33px);
                    }

                    @include setMaxRwd{
                        br{
                            display: none;
                        }
                    }
                }
            }

            // 沒有框線的狀態
            &-noBorder{
                .content-nav-list {
                    flex: none;
                }
                
                .content-item{
                    border:none;
                    .title{
                        color:#606060;
                        font-size: get-vw-mobile(22px);
                        
                        @include setStyleMinRwd(1100px){
                            font-size: get-vw(22px);
                        }

                        @include setWidthRwd(1101px, 1600px){
                            @include fontSize(21px)
                        }

                        @include setWidthRwd(900px, 1100px){
                            @include fontSize(20px)
                        }
                    }

                    @include setWidthRwd(1101px,1600px){
                        padding-left: 1em;
                        padding-right: 1em;
                    }
                }

                .content-link{
                    &::before {
                        @include setSize(72px,10px);
                        bottom: 0;
                        margin-left: -36px;
                        transition: 0s;
                    }
                    &::after {
                        display: none;
                    }

                    &:hover,
                    &.active{
                        &::before {
                            background-image: url('../images/content-line-active.png');
                            bottom: 3px;
                        }
                        &::after {
                            display: block !important;
                            @include setSize(100%,2px);
                            border-radius: 0;
                            left: 0;
                            bottom: 10px;
                            top: auto;
                        }
                        @include setMaxRwd{
                            &::before {
                                bottom: 0;
                            }
                            &::after {
                                bottom: 7px;
                            }
                        }
                    }
                }
            }

            // 比一般尺寸小
            &.small-xs{
                .content-item{
                    .title{
                        @include setMinRwd{
                            @include fontSize(20px);
                        }
                    }
                }
            }

            // 彈性
            &.default{
                .content-nav-list{
                    flex: 1 0 auto !important;
                    max-width: 100% !important;
                }

                @include setMinRwd{
                    .content-item {
                        min-height: 100px;
                    }
                }

                @include setMaxRwd{
                    .content-nav-list{
                        margin: 5px;
                    }

                    .content-item{
                        .info {
                            padding: 0.5em 1em;
                        }
                    }
                }
            }

            @include setWidthRwd(1101px,1600px){
                .content-item{
                    min-height: auto;
                }
            }

            @include setMaxRwd{
                justify-content: space-around;
                margin-bottom: 1em;
                .content-link{
                    &:hover,
                    &.active{
                        &::before,
                        &::after{
                            display: none !important;
                        }
                        
                        .title{
                            color:#000;
                        }
                    }
                }
            }
        }

        @include setMaxRwd{
            justify-content: flex-start !important;
            margin-bottom: 0 !important;
            margin-left: -0.5rem;
            margin-right: -0.5rem;
            &-list{
                flex: 0 0 calc((1/3*100%) - 4px) !important;
                max-width: calc((1/3*100%) - 4px) !important;
                margin: 0 2px 5px 2px;
                background: #eee;
                *{
                    border:none
                }
            }
        }

    }

    // 一般尺寸 : 第二層
    &-nav-dropdown{
        min-width: 100vw;
        padding: 1em 0.25em;
        margin-bottom: 1.5em;
        background: #E5E5E5;
        position: absolute;
        left: 0.75em;
        top: 7em;
        z-index: 1;

        &::before{
            content: "";
            display: block;
            @include setSize(1000%,100%);
            background: #E5E5E5;
            position: absolute;
            left: -500%;
            top: 0;
            z-index: 0;
        }

        &-container{
            position: relative;
            z-index: 1;
            @extend %setFlex;
            width: 100%;
            li{
                position: relative;
                padding: 0 2em;
                &::after{
                    content: "";
                    @include setSize(1px,15px);
                    background-color: #5F5F5F;
                    position: absolute;
                    left: 100%;
                    top: 50%;
                    margin-top: -7.5px;
                }
                @include setMaxRwd{
                    padding-left:1em;
                    padding-right:1em;
                    &::after{
                        margin-top: -4.5px;
                    }
                }
            }

            a{
                font-size: get-vw-mobile(24px);
                line-height: 1.5;
                color: #6c757d;
                &:hover,
                &.active{
                    color:#2B2B2B
                }
                @include setMinRwd{
                    @include fontSize(20px);
                }
            }
        }

        @include setMaxRwd{
            position: relative;
            left: 0;
            top: 0;
            padding-top: 0.25em;
            padding-bottom: 0.5em;
            margin-bottom: 0;
            &-container{
                a{
                    font-size: get-vw-mobile(23px);
                }
            }
        }

        &.normal{
            position: relative;
            top: 0;
            left: 0;
            min-width: auto;
            overflow: hidden;
            .content-nav-dropdown-container{
                justify-content: center;
            }
        }
    }

    @include setMaxRwd{
        &-link{ 
            &.active{
                &::before,
                &::after{
                    display: none !important;
                }
            }
        }
    }
}

// 
.tag-content{
    text-align: center;
    a{
        border-radius: 25px;
        margin-left: 6px;
        margin-right: 6px;
        &.active{
            border-color: $colorMain;
            background-color: $colorMain;
            color:#fff
        }

        @include setMaxRwd{
            margin-top: 5px;
            margin-bottom: 10px;
            font-size: get-vw-mobile(20px);
        }
    }
}

// -------- OTHER -------- //

.select_style {
    &::after {
        content: '\f0d7';
        font-family:'fontAwesome';
        display: block;
        @include fontSize(14px);
        position: absolute;
        right: 8px;
        top: 1px;
    }

    select.form-control {
        border: 1px solid #e6eaea !important;
        background: #e6eaea;
        text-align: left;
        border-radius: 0;
        height: 34px !important;
        padding-top: 0;
        padding-bottom: 0; 
    }

    select {
        width: 100%;
        background: transparent;
        appearance: none;
        -webkit-appearance: none;
    }
    select,
    input {
        border: none !important;
        height: 100% !important;
    }
    .form-control {
        border-radius: 0;
    }
    .disabled {
        background-color: #e9ecef;
        select,
        input {
            background: transparent;
        }
    }
}

.count_style{
    border: 1px solid #e6eaea !important;
    background: #e6eaea;
    text-align: left;
    border-radius: 0;
    display: flex;
    align-items: center;
    padding: 3px;
    .item{
        .form-control{
            height: auto;
            padding: 0;
            background-color: transparent;
            text-align: center
        }
    }
    .add,
    .minus{
        width: 30px;
        flex: none;
        text-align: center;
        padding: 0;
        &.btn{
            .fa{
                @include fontSize(14px);
                margin: 0 !important;
                color: $colorBase
            }
        }
    }
}

// 樣式 : Tab分頁
.nav-tabs{
    --bs-nav-tabs-border-color: #989798;
    .nav-item{
        &~ .nav-item{
            margin-left: 5px;
        }
        @include setMinRwd{
            flex-basis: 270px;
        }
    }

    .nav-link{
        width: 100%;
        color: var(--bs-nav-tabs-link-active-color);
        @include radius(0);
        border-color: #c4c5c9;
        background-color: #fff;
        border-bottom-width: 0;
        margin-bottom: -2px;
        padding-top: 0.65em;
        padding-bottom: 0.65em;
        &.active{
            background-color: #eeeeee;
            border-color: #989798;
            box-shadow: 0px 0 5px rgba(0,0,0,0.15);
        }

        @include setMinRwd{
            @include fontSize(22px);
        }

        @include setMaxRwd{
            flex: 1;
            padding-left: 0.65em;
            padding-right: 0.65em;
        }
    }

    &+.tab-content{
        padding: 1em;
        background-color: #eeeeee;
        @include setMinRwd{
            padding: 1em;
        }

        @include setStyleMinRwd(1300px){
            padding: 3em 6em;
        }
    }
}

// 麵包屑
.breadcrum-wrap {
    border-top: #b5b5b5 1px solid;
    border-bottom: #b5b5b5 1px solid;
    margin-top: -6px;
    background: #e5e5e5;
    mix-blend-mode: multiply;
    .breadcrumb {
        width: 100%;
        @include fontSize(16px);
        color: #000000;
        background-color: transparent;
        margin-bottom: 0;
        .breadcrumb-item{
            padding: 0.75em 1.25rem;
            border-right: #b5b5b5 1px solid;
            &:first-child{
                border-left: #b5b5b5 1px solid;
            }
            &.active{
                position: relative;
                &::after {
                    content: "";
                    display: inline-block;
                    vertical-align: .255em;
                    margin-left: .255em;
                    border-top: .3em solid;
                    border-right: .3em solid transparent;
                    border-bottom: 0;
                    border-left: .3em solid transparent;
                    position: absolute;
                    right: 0.3em;
                    top: 50%;
                    margin-top: -0.15em;
                }

                @include setMinRwd{
                    flex-basis: 230px;
                }
            }
            &+.breadcrumb-item{
                &::before {
                    display: none;
                }
            }
        }

        @include setWidthRwd(1101px,1350px){
            padding-left: 3em;
        }
    }

    @include setMinRwd{
        .wrapper{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .breadcrumb{
                flex: none;
            }
            
            .search-input-group {
                flex-basis: 27em;
            }
        }
    }

    @include setWidthRwd(1101px,1350px){
        .wrapper{
            .search-input-group {
                padding-right: 3em;
            }
        }
    }

    @include setMaxRwd{
        padding-left: 10px;
        padding-right: 10px;
    }
}

// 搜尋條件
.search-input-group{
    .form-select,
    .form-control,
    .btn{
        border-width:0 0 2px 0;
        border-bottom-color: #858485;
        outline: none;
        border-radius: 0
    }

    .form-select{
        flex: none;
        width: 150px;
    }

    .form-control{
        margin-left: 10px !important;
        margin-right: 5px;
    }

    .btn{
        border: none;
        padding-left: 15px;
        padding-right: 15px;
    }
}

// 頁碼
.pagination-wrap {
    @extend %setFlex;
    justify-content: center;
    .pagination {
        justify-content: center;
        .page-link {
            @include setSize(25px,25px);
            @include fontSize(16px);
            line-height: 25px;
            color: #6e6d6d;
            font-family: $Roboto;
            @include radius(0);
            text-align: center;
            padding: 0;
            margin-right: 1rem;
            margin-left: 0.5rem;
            background-color: transparent;
            border: none;
            &:hover,
            &.active {
                color: #000;
            }

            &.prev,
            &.next{
                background-color: #424242;
                border-color: #424242;
                color: #fff;
                @include radius(4px);
                margin-left: 2em;
                margin-right: 2em;
            }
        }

        .page-item{
            position: relative;
            & + .page-item{
                &::after{
                    content: ' / ';
                    display: inline-block;
                    padding: 0 5px;
                    @include fontSize(12px);
                    color: #4D4D4D;
                    position: absolute;
                    left: -12px;
                    top: 2px;
                }
            }
            
        }
    }

    .pagination-select{
        .select_style{
            width: 100%;
            &::after{
                top: 50%;
                transform: translateY(-50%);
            }
        }

        @include setMinRwd{
            width: 60px;
        }

        @include setMaxRwd{
            display: flex;
            justify-content: center;
            width: 175px;
        }
    }

    .fa{
        font-size: 1.2em;
    }

    @include setMaxRwd{
        margin-top: 0.5rem;
        margin-bottom: 2rem;
    }
}

// 項目
.tag-item {
    display: inline-block;
    width: auto;
    line-height: 1.1;
    margin: 2.5px;
    font-size: 14px;
    line-height: 1;
    padding: 6px;
    text-align: center;
    background-color: $bgSecondary;
    color: $colorText; 

    &.tag{
        color: #4D4D4D;
        background-color: #e5e5e5;
        line-height: 25px;
        padding: 0.5em 1em;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        @include fontSize(14px);
        @include setMinRwd{
            @include fontSize(18px);
            padding: 8px 24px;
        }
    }

    &.main {
        background-color: $colorMain;
        @include radius(30px);
        color:#fff;
        padding: 0.5rem 1rem;
    }

    &.new{
        @include radius(30px);
        background-color: #7eba2e;
        color:#fff;
        padding: 0.5rem 1rem;
        .txt{
            color:#ffe42d;
            @include fontSize(14px);
            padding-left: 5px;
        }
    }

    &.default{
        border:#dbdbdb 1px solid;
        background-color: #fff;
        border-radius: 10px;
        color:#5f5f5f;
        font-size: 16px !important;
        padding: 6px 12px;
    }

    &.dark{
        background-color: $colorText;
        color:#fff;
    }

    &.white{
        display: inline-flex;
        align-items: center;
        border:#fff 1px solid;
        background-color: #fff;
        font-size: 16px !important;
        padding: 6px 12px;
        line-height: 1.5;
        .icon{
            margin-right: 10px;
        }
    }
}

// Swiper 調整
.swiper{
    &-container {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    &-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    // <div class="swiper-effects">
    // <div class="swiper-button-prev"></div>
    // <div class="swiper-pagination"></div>
    // <div class="swiper-button-next"></div>
    // </div>

    &-effects{
        @extend %setFlex-center;
        position: relative;
        top: 0.5em;
        // justify-content: flex-end;
        // padding-right: 1.25em;
        // @include setMinRwd{
        //     padding-right: 11.25em;
        // }

        .swiper-pagination,
        .swiper-button-prev,
        .swiper-button-next{
            position: static;
            width: auto;
            margin: 0;
        }

        .swiper-pagination{
            padding: 0 25px;
            font-family: $Roboto;
            color:#bbbbbb;
            .swiper-pagination-current{
                color:$colorBase
            }
        }

        .swiper-button-prev,
        .swiper-button-next{
            &::after{
                @include fontSize(20px);
                color:$colorBase;
            }
        }
    } 
}

// card 文字內容 & 框
.cata{
    &Txt{
        @include fontSize(16px);
        color:$colorList;
        line-height: 1.5;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 2.5px;
  
        @include setMaxRwd{
            padding-top: 0;
            padding-bottom: 5px;
        }
    }
  
    &List{
        width: 100%;
        padding: 1em 1em 0.5em;
        z-index: 0;
        cursor: pointer;
  
        @include setMinRwd{
            margin-top: 0.5em;
        }
  
        @include setMaxRwd{
          padding: 0.5em;
          padding-bottom: 0.25em;
        }
  
        &::before{
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: #fff;
            border: #e8e8e8 1px solid;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
  
        &-wrap{
          &:hover{
              .cataList{
                  &::before{
                    box-shadow: 0 7px 10px -5px rgba(0, 0, 0, 0.1) !important;
                  }
              }
          }
        }

        &Img{
            margin-bottom: 0.25rem;
            overflow: hidden;
            height: 9em;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                max-height: 100%;
            }
            @include pad{
                height: 29vw;
                align-items: flex-start;
            }
        }
    }
}

// Checkbox & radio
.checkbox{
    &_style {
        @extend %setFlex-center;
        &>input {
            &[type="checkbox"] {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
                opacity: 0;
                cursor: pointer;
            }
            &+label {
                position: relative;
                display: flex;
                align-items: center;
                text-align: left;
                margin-top: 2px;
                margin-bottom: 5px;
                margin-right: 2px;
                margin-left: 0;
                padding-left: 1.75rem;
                cursor: pointer;
                border: none;
                &::before {
                    content: "";
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    font-size: 1.5rem;
                    position: absolute;
                    left: 0;
                    background-color: #E5E5E5;
                }
                &::after {
                    content: "\f00c";
                    font-family: FontAwesome;
                    position: absolute;
                    left: 2px;
                    font-size: 1rem;
                    color:#fff;
                    opacity: 0;
                }
            }
            &:checked+label::before{
                background-color: $colorMain;
                border-color: $colorMain;
            }
            &:checked+label::after {
                opacity: 1;
            }
        }
        p {
            margin-top: 10px;
        }
    }
}

.radio{
    &_style {
        @extend %setFlex-center;
        margin-top: 10px !important;
        input {
            &[type="radio"] {
                display: none;
            }
            &+label {
                position: relative;
                display: flex;
                align-items: center;
                line-height: 1;
                padding-left: 30px;
                margin-bottom: 0;
                &::before,
                &::after {
                    content: "";
                    display: inline-block;
                    border-radius: 50%;
                }
                &::before {
                    width: 20px;
                    height: 20px;
                    border: #cecece 1px solid;
                    background: #fff;

                    position: absolute;
                    top: 1px;
                    left: 0;
                }
                &::after {
                    width: 10px;
                    height: 10px;
                    background-color: transparent;
                    position: absolute;
                    left: 5px;
                    top: 6px;
                }
            }
            &:checked {
                &+label {
                    &::after {
                        background-color: $colorMain;
                    }
                }
            }
        }
        @include setMinRwd {
            &~.radio_style {
                
            }
        }
    }
}

.form-group-list{
    @extend %setFlex;
    .item{
        flex: 1;
        @include setMaxRwd{
            padding-right: 10px;
        }
    }
}

// 收合樣式
.accordion{
    // 樣式
    .btn {
        position: relative;
        font-size: get-vw-mobile(30px);
        font-weight: 600;
        color:$colorText;
        text-decoration: none !important;
        padding-left: 0;

        &::after{
            content: "\f068";
            font-family: FontAwesome;
            position: absolute;
            right: 0;
            top: 10px;
        }
        
        &.collapsed{
            &::after{
                content: "\f067";
            }
        }

        @include setMinRwd{
            @include fontSize(24px);
        }
    }

    .card{
        border:none;

        &>.card-header {
            background: transparent;
            padding-left: 0.3rem;
            padding-bottom: 0.3rem;
            margin-bottom: 0;
        }

        .card-body {
            line-height: 150%;
        }

        @include setMinRwd{
            .card-body {
                padding-left: 2.5em;
            }
        }
    }
}

// 圖文用
.pageBlog-content{
    @include setMinRwd{
        padding-top: 4em;

        .pageTitle{
            width: 26em;
            padding-left: 1em;
        }
    
        .pageIimg{
            flex: 1;
        }

        .btn{
            padding-left: 1.75em !important;
            padding-right: 1.75em !important;
            padding-bottom: 0.65em !important;
        }
    
        .ser-contentIntro-item-list{
            margin-bottom: 4em;
    
            &:nth-of-type(odd){
                margin-right: 0;
                .pageTitle {
                    padding-right: 5em;
                }
            }
    
            &:nth-of-type(even){
                margin-right: auto;
                margin-left: 0;
                .pageImg{
                    order:-1
                }
                .pageTitle {
                    padding-left: 5em;
                }
            }

            &:last-of-type{
                margin-bottom: 0;
                padding-bottom: 4em;
            }
        } 
    }

    @include setWidthRwd(1100px,1400px){
        .ser-contentIntro-item-list{
            .pageTitle {
                padding-left: 2em !important;
                padding-right: 2em !important;
            }
        } 
    }

    @include setMaxRwd{
        padding:1em 1em 0;
        .pageImg{
            margin-top: 1em;
            margin-bottom: 1em;
        }
    }
}

// 表格的卷軸
.table-overHidden{
    display: block;
    width: 100%;
    overflow: hidden;
    &::after{
        content: '若看不到完整內容，請向左滑動';
        padding:0.75em 1em 1em;

        @extend .tag-item;
        background-color: $colorBlack;
        @include radius(20px);
        color:#fff;
        margin-bottom: 0.75em;

        @include setMinRwd{
            display: none;
        }
    }
    
    @include setMaxRwd{
        overflow-x:auto;
    }
}
