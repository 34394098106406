$card-left: 1em;
$card-right: 1em;

$mobile-card-left: 0.5em;
$mobile-card-right: 0.5em;

.default-card-wrap{
    &.card-wrap{
        @extend %setFlex;
        margin-top: 1.25em;

        @include setMaxRwd{
            margin-top: 0.25em;
        }
    }
    
    .card{
        flex-grow: 1;
        @include radius(0);
        border: none;
        background: transparent;
        margin-left: $card-left;
        margin-right: $card-right;
        margin-bottom: $card-right;

        // Grid
        &__2{
            @include setStyleMinRwd(1600px){
                flex: 0 0 calc(100% / 2 - ($card-left + $card-right)) !important;
                max-width: calc(100% / 2 - ($card-left + $card-right)) !important;
            }
        }

        &__3{
            @include setMinRwd{
                flex: 0 0 calc(100% / 3 - ($card-left + $card-right)) !important;
                max-width: calc(100% / 3 - ($card-left + $card-right)) !important;
            }
        }

        &__4{
            @include setMinRwd{
                flex: 0 0 calc(100% / 4 - ($card-left + $card-right)) !important;
                max-width: calc(100% / 4 - ($card-left + $card-right)) !important;
            }
        }

        @include setMaxRwd{
            margin-left: $mobile-card-left;
            margin-right: $mobile-card-right;
            flex: 0 0 calc(100% / 2 - ($mobile-card-left + $mobile-card-right));
            max-width: calc(100% / 2 - ($mobile-card-left + $mobile-card-right));
        }

        &__full{
            flex: 0 0 calc(100% - ($card-left + $card-right)) !important;
            max-width: calc(100% - ($card-left + $card-right)) !important;
        }

        &__horizontal{
            @include setMinRwd{
                flex-direction: row;
            }
        }
        // End

        &-img-top{
            @include radius(0);
            img{
                display: block;
                width: 100%;
            }
        }

        &-body{
            background-color: transparent;
            padding: 1.25em 0 0;
            @include setMaxRwd{
                padding-top: 0.25em;
            }
        }
            
        &-title{
            @include fontSize(20px);
            margin-top: 0.25em;
        }

        &-btn{
            .btn{
                @include radius(6px);
            }
        }

        &-time{
            font-family: $Roboto;
            .date{
                @include fontSize(30px);
                font-weight: 600;
            }

            .year{
                margin-top: 3px;
            }
        }

        &:hover{
            .card-img-top{
                img{
                    @extend .boxShadow_p20
                }
            }
        }

        @include setMaxRwd{
            margin-bottom: 1em;

            &-time{
                .date,
                .year{
                    margin-bottom: 0;
                } 
            }

            &-caption {
                padding-top: 0.125em;
                padding-bottom: 0.25em;
            }
        }
    }
}