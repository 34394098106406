/* #Navigation
================================================== */

$headerTrans : all 0.3s ease-out;
$headerLink :#B4B2B2;

.start-header {
	opacity: 1;
	transform: translateY(0);
	box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
	transition : $headerTrans;
    &.scroll-on {
        transition : $headerTrans;
    }

    img{
        margin: 0;
    }

    .container{
        &,
        &-fluid{
            padding: 0;
        }

        &{
            max-width: 1570px;
        }
    }
}

.navigation-wrap{
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1050;
	transition : $headerTrans;
}

// 參考範例 : https://codepen.io/typo3-freelancer/pen/poEvyGj

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

// Assigning the above animation to all dropdown menus
.dropdown-menu{
    &.show{
        min-width: 7em;
        top: calc(100% + 12.5px);
        animation: fadeIn 0.3s alternate;
    }

    @include setMaxRwd{
        display: flex;
        &,
        .dropdown-item{
            background: transparent;
            color: #fff;
        }

        li{
            margin-left: 10px;
        }

        .dropdown-item{
            border-bottom: #fff 1px solid;
        }
    }
}

// Mega Menu adjustments
.nav-item{
    img{
        margin: 0;
    }
    .dropdown.dropdown-mega{
        position: static;
        .dropdown-menu{
            width: 90%;
            top: auto;
            left: 5%;
        }
    }
}

// Complete styles for main navbar toggler and hamburger menu
.navbar{
    padding: 0;

    &-collapse{
        width: 100%;
        padding-top: 3px;
        padding-bottom: 3px;
        .navbar-nav{
            position: relative;
            max-width: 90em;
            margin: auto;
            padding: 12.5px 0;
        }
    }

    // 
    &-nav{
        .nav-item{
            padding-left: 15px;
            padding-right: 15px;
            &::after{
                content: "/";
                display: inline-block;
                position: absolute;
                right: 0;
                top: -5px;
                color: #dfdede;
                @include setWidthRwd(800px,1350px){
                    top: 7px;
                }
            }
        }

        .nav-link {
            @include setMinRwd{
                @include fontSize(20px);
                color: #5b5b5c;
                line-height: 1;
                padding-top: 0;
                padding-bottom: 3px;
                &::before{
                    content: '';
                    display: block;
                    @include setSize(100%,100%);
                    max-width: 105px;
                    max-height: 13px;
                    @include setBackSet(top,contain);
                    background-image: url('../images/navbar-hover.png');
                    position: absolute;
                    top: -1.6em;
                    left: 0;
                    transition: 0.1s;
                    transform: translateY(-150%);
                }

                &:hover,
                &.active{
                    color: black;
                    &::before{
                        transform: translateY(0);
                    }
                }
            }

            @include setWidthRwd(800px,1350px){
                @include fontSize(17px);
            }
        }
    }
    
    &-brand{
        flex: none;
        max-width: 355px;
        margin-right: 0;
        margin-bottom: 0.5em;
        padding-top: 0;
        padding-bottom: 0;
        @include setWidthRwd(800px,1350px){
            max-width: 270px;
        }
        @include setMaxRwd{
            margin-left: 10px;
        }
    }
    
    &-toggler{
        border: none;
        padding: 0;
        outline: none;
        &:focus{
            box-shadow: none;
        }
        .hamburger-toggle{
            position: relative;
            display: inline-block;
            width: 50px;
            height: 50px;
            z-index: 11;
            .hamburger {
                position: absolute;
                transform: translate(-50%, -50%) rotate(0deg);
                left: 50%;
                top: 50%;
                width: 50%;
                height: 50%;
                pointer-events: none;
                span {
                    width: 100%;
                    height: 4px;
                    position: absolute;
                    background: #333;
                    border-radius: 2px;
                    z-index: 1;
                    transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0), background 0.2s cubic-bezier(0.77,0.2,0.05,1.0), all 0.2s ease-in-out;
                    left: 0px;
                    &:first-child{
                        top: 10%;
                        transform-origin: 50% 50%;
                        transform: translate(0% -50%) !important;
                    }
                    &:nth-child(2){
                        top: 50%;
                        transform: translate(0,-50%);
                    }
                    &:last-child{
                        left: 0px;
                        top: auto;
                        bottom: 10%;
                        transform-origin: 50% 50%;
                    }
                }
                &.active{
                    span {
                        position: absolute;
                        margin: 0;
                        &:first-child{
                            top: 45%;
                            transform: rotate(45deg);
                        }
                        &:nth-child(2){
                            left: 50%;
                            width: 0px;
                        }
                        &:last-child{
                            top: 45%;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
    }

    &-btn-member{
        max-width: 6vw;
        position: absolute;
        right: 55px;
        top: 8px;
    }
}

.icons{
  display: inline-flex;
  margin-left: auto;
  a{
    transition: all 0.2s ease-in-out;
    padding: 0.2rem 0.4rem;
    color: #ccc !important;
    text-decoration: none;
    &:hover{
      color: white;
      text-shadow: 0 0 30px white;
    }
  }
}