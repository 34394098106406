@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("//fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("//fonts.googleapis.com/css2?family=Bellefair&display=swap");
a, body {
  color: #212121;
  font-size: 18px;
  font-family: "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
  -webkit-text-stroke: 0.1px;
  line-height: 1.65;
  font-weight: 400;
}

.sprite, input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.contact-info .txt, .contact-block, .Prod-unreset dl, .Prod-conInfo, .aboutBox, .default-card-wrap.card-wrap, .form-group-list, .pagination-wrap, .content-nav-dropdown-container, .heading-title.search .txt, .mainContent {
  display: flex;
  flex-wrap: wrap;
}

.Prod-conInfo-contactList li, .form-check, .btn-wrap, .radio_style, .checkbox_style, .swiper-effects, .content-item, .heading-title, footer .footer-icon, footer .footer-info-wrap, .pageloading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.font-size-1 {
  font-size: 87.5% !important;
}

.font-size-2 {
  font-size: 100% !important;
}

.font-size-3 {
  font-size: 112.5% !important;
}

.font-size-4 {
  font-size: 150% !important;
}

.font-size-5 {
  font-size: 175.5% !important;
}

.font-size-6 {
  font-size: 250% !important;
}

.font-size-7 {
  font-size: 275% !important;
}

.font-size-8 {
  font-size: 362.5% !important;
}

.font-size-9 {
  font-size: 450% !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 1.75 !important;
}

.line-height-5 {
  line-height: 2 !important;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

.fontArial {
  font-family: Arial;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
  overflow-x: hidden;
}
body.fixed {
  overflow: hidden;
}
@media (max-width: 1600px) {
  body {
    font-size: 18px;
  }
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.sh_sans {
  font-family: "source-han-sans-traditional", "微軟正黑體", sans-serif;
}

.sh_serif {
  font-family: "source-han-serif-tc", serif;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

.sprite {
  fill: #ae8c5d;
  vertical-align: top;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.font-button .far,
.font-button .fas {
  color: #666;
  transition: 0.08s cubic-bezier(0.37, 0.99, 0.92, 0.96);
}
.font-button:hover .far,
.font-button:hover .fas {
  color: #ae8c5d;
}

.zero-size {
  font-size: 0px;
  letter-spacing: 0px;
  word-spacing: 0px;
}

.required {
  color: #ae8c5d;
  margin: 0 0 0 8px;
}

.red {
  color: #ff0016;
}

.space {
  display: inline-block;
  width: 7px;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1024px) {
  .hide {
    display: block !important;
  }
}

@keyframes menushow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes visitsLoad {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  30% {
    transform: translate3d(-70%, 0, 0);
  }
  80% {
    transform: translate3d(-20%, 0, 0);
  }
  95% {
    transform: translate3d(-5%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}
@keyframes visitsShow {
  0% {
    opacity: 0;
    transform: translate3d(0, 45px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.table {
  border-top: 1px solid #c8dae8;
  border-right: 1px solid #c8dae8;
  border-left: 1px solid #c8dae8;
}

.input {
  width: 100%;
}

.textarea {
  width: 100%;
  height: 100px;
}

.searchBar {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}

.mw-auto {
  max-width: none !important;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1050 {
  width: 100%;
  max-width: 1050px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-950 {
  width: 100%;
  max-width: 950px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.wrapper-40e {
  width: 100%;
  max-width: 40em;
  margin: auto;
}

.detail_label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail_label.fc-LightSalmon {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}

.pageloading {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s 0.2s;
  z-index: 10000;
}
.pageloading .loading {
  width: 200px;
  height: 8px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
}
.pageloading .loading .bar {
  width: 0%;
  height: 100%;
  background-color: #ae8c5d;
}
.pageloading.compelet {
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

@keyframes jumping {
  50% {
    top: 0;
  }
  100% {
    top: -50px;
  }
}
@keyframes eyemove {
  0%, 10% {
    transform: translateX(50%);
  }
  90%, 100% {
    transform: translateX(-50%);
  }
}
.letter {
  float: left;
  font-size: 18px;
  font-size: 1.125rem;
  color: #000;
}

.letter-holder .letter {
  animation-name: loadingF;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

.l-1 {
  animation-delay: 0.48s;
}
.l-2 {
  animation-delay: 0.53s;
}
.l-3 {
  animation-delay: 0.58s;
}
.l-4 {
  animation-delay: 0.63s;
}
.l-5 {
  animation-delay: 0.69s;
}
.l-6 {
  animation-delay: 0.74s;
}
.l-7 {
  animation-delay: 0.79s;
}
.l-8 {
  animation-delay: 0.84s;
}
.l-9 {
  animation-delay: 0.89s;
}
.l-10 {
  animation-delay: 0.94s;
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.js-loadHide {
  display: none !important;
}

.loader {
  margin: 0 0 2em;
  height: 100px;
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
}

/* Set the color of the icon */
svg path,
svg rect {
  fill: #ae8c5d;
}

/* #Navigation
================================================== */
.start-header {
  opacity: 1;
  transform: translateY(0);
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  transition: all 0.3s ease-out;
}
.start-header img {
  margin: 0;
}
.start-header .container, .start-header .container-fluid {
  padding: 0;
}
.start-header .container {
  max-width: 1570px;
}

.navigation-wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  transition: all 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.dropdown-menu.show {
  min-width: 7em;
  top: calc(100% + 12.5px);
  animation: fadeIn 0.3s alternate;
}
@media (max-width: 1024px) {
  .dropdown-menu {
    display: flex;
  }
  .dropdown-menu,
.dropdown-menu .dropdown-item {
    background: transparent;
    color: #fff;
  }
  .dropdown-menu li {
    margin-left: 10px;
  }
  .dropdown-menu .dropdown-item {
    border-bottom: #fff 1px solid;
  }
}

.nav-item img {
  margin: 0;
}
.nav-item .dropdown.dropdown-mega {
  position: static;
}
.nav-item .dropdown.dropdown-mega .dropdown-menu {
  width: 90%;
  top: auto;
  left: 5%;
}

.navbar {
  padding: 0;
}
.navbar-collapse {
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
}
.navbar-collapse .navbar-nav {
  position: relative;
  max-width: 90em;
  margin: auto;
  padding: 12.5px 0;
}
.navbar-nav .nav-item {
  padding-left: 15px;
  padding-right: 15px;
}
.navbar-nav .nav-item::after {
  content: "/";
  display: inline-block;
  position: absolute;
  right: 0;
  top: -5px;
  color: #dfdede;
}
@media (min-width: 800px) and (max-width: 1350px) {
  .navbar-nav .nav-item::after {
    top: 7px;
  }
}
@media (min-width: 1025px) {
  .navbar-nav .nav-link {
    font-size: 20px;
    font-size: 1.25rem;
    color: #5b5b5c;
    line-height: 1;
    padding-top: 0;
    padding-bottom: 3px;
  }
  .navbar-nav .nav-link::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    max-width: 105px;
    max-height: 13px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    background-image: url("../images/navbar-hover.png");
    position: absolute;
    top: -1.6em;
    left: 0;
    transition: 0.1s;
    transform: translateY(-150%);
  }
  .navbar-nav .nav-link:hover, .navbar-nav .nav-link.active {
    color: black;
  }
  .navbar-nav .nav-link:hover::before, .navbar-nav .nav-link.active::before {
    transform: translateY(0);
  }
}
@media (min-width: 800px) and (max-width: 1350px) {
  .navbar-nav .nav-link {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}
.navbar-brand {
  flex: none;
  max-width: 355px;
  margin-right: 0;
  margin-bottom: 0.5em;
  padding-top: 0;
  padding-bottom: 0;
}
@media (min-width: 800px) and (max-width: 1350px) {
  .navbar-brand {
    max-width: 270px;
  }
}
@media (max-width: 1024px) {
  .navbar-brand {
    margin-left: 10px;
  }
}
.navbar-toggler {
  border: none;
  padding: 0;
  outline: none;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler .hamburger-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 11;
}
.navbar-toggler .hamburger-toggle .hamburger {
  position: absolute;
  transform: translate(-50%, -50%) rotate(0deg);
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  pointer-events: none;
}
.navbar-toggler .hamburger-toggle .hamburger span {
  width: 100%;
  height: 4px;
  position: absolute;
  background: #333;
  border-radius: 2px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), all 0.2s ease-in-out;
  left: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger span:first-child {
  top: 10%;
  transform-origin: 50% 50%;
  transform: translate(0% -50%) !important;
}
.navbar-toggler .hamburger-toggle .hamburger span:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}
.navbar-toggler .hamburger-toggle .hamburger span:last-child {
  left: 0px;
  top: auto;
  bottom: 10%;
  transform-origin: 50% 50%;
}
.navbar-toggler .hamburger-toggle .hamburger.active span {
  position: absolute;
  margin: 0;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:first-child {
  top: 45%;
  transform: rotate(45deg);
}
.navbar-toggler .hamburger-toggle .hamburger.active span:nth-child(2) {
  left: 50%;
  width: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:last-child {
  top: 45%;
  transform: rotate(-45deg);
}
.navbar-btn-member {
  max-width: 6vw;
  position: absolute;
  right: 55px;
  top: 8px;
}

.icons {
  display: inline-flex;
  margin-left: auto;
}
.icons a {
  transition: all 0.2s ease-in-out;
  padding: 0.2rem 0.4rem;
  color: #ccc !important;
  text-decoration: none;
}
.icons a:hover {
  color: white;
  text-shadow: 0 0 30px white;
}

footer {
  color: #fff;
}
footer .footer-top, footer .footer-bottom {
  display: flex;
}
footer .footer-top {
  background-color: #000;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (min-width: 1101px) and (max-width: 1600px) {
  footer .footer-top {
    padding: 1em 2em;
  }
}
@media (max-width: 1024px) {
  footer .footer-top {
    padding: 1em 0.25em;
  }
}
footer .footer-bottom {
  align-items: center;
  justify-content: center;
  background-color: #4b4a4a;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 100;
  line-height: 1.5;
  color: #fff;
  padding-top: 1em;
  padding-bottom: 1em;
}
footer .footer-info {
  font-size: 0.875em;
  font-weight: 100;
}
@media (max-width: 1024px) {
  footer .footer-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  footer .footer-info span {
    padding-right: 10px;
  }
}
@media (max-width: 1024px) {
  footer .footer-info-bottom {
    width: 100%;
    margin-top: 1.5em !important;
  }
  footer .footer-info-bottom .footer-icon,
footer .footer-info-bottom .copyright p {
    justify-content: center;
  }
  footer .footer-info-bottom .footer-info,
footer .footer-info-bottom .copyright {
    flex-direction: column;
  }
}
footer .footer-info-wrap {
  width: 100%;
}
@media (max-width: 1024px) {
  footer .footer-info-wrap {
    flex: auto;
    width: 100%;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  footer .footer-info-wrap li + li {
    margin-top: 0.5em;
  }
}
footer .footer-info-list {
  flex: 1;
}
footer .footer-info-list .bi {
  color: #aaaaaa;
  margin-right: 10px;
}
footer .footer-info-location {
  max-width: 500px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 640px) {
  footer .footer-info-location {
    flex-direction: column;
  }
}
footer .footer-title {
  margin-bottom: 0.5em;
}
footer .footer-title h6 {
  font-size: 24px;
  font-size: 1.5rem;
}
footer .footer-icon {
  justify-content: flex-end;
  margin-bottom: 0.5em;
}
footer .footer-iconLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5em;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
}
footer .footer-iconLink .svg {
  width: 40px;
  height: 40px;
}
footer .footer-link a {
  display: block;
  font-size: 0.875em;
  margin-bottom: 0.5em;
}
@media (max-width: 1024px) {
  footer .footer-link {
    display: flex;
    justify-content: space-between;
  }
}
footer .footer-txt {
  color: #d7e7f2;
}
footer .footer-logo {
  display: flex;
  align-items: center;
  padding-right: 50px;
}
footer .footer-logo img {
  display: block;
  max-width: 100%;
}
@media (max-width: 1024px) {
  footer .footer-logo {
    padding-right: 1.5em;
  }
}
@media (max-width: 1024px) {
  footer {
    margin-bottom: 2.2em;
  }
}

.divider {
  display: inline-block;
  width: 1px;
  height: 15px;
  background: #bfb1a5;
  vertical-align: middle;
  margin: 0 10px;
}
@media (max-width: 1024px) {
  .divider {
    display: none;
  }
}

.iware {
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  opacity: 0.5;
  padding: 5px 10px;
}
.iware,
.iware a {
  color: white;
}
.iware a:hover {
  text-decoration: underline;
}
.iware b {
  font-weight: 100;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
@media (min-width: 1025px) {
  .float-link .link-btn.nabar-icon-mobile {
    display: none;
  }
}
.float-link .link-btn.nabar-icon-mobile img {
  max-width: 100%;
}
@media (max-width: 1024px) {
  .float-link .link-btn.top {
    display: none;
  }
}
.float-link.active {
  right: 5px;
}
@media (max-width: 1600px) {
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1024px) {
  .float-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: #000;
    padding: 5px;
    margin: 0;
  }
  .float-link .link-btn {
    text-align: center;
    margin: 0;
  }
  .float-link .link-btn,
.float-link .link-btn .link-btn-toggle {
    display: block;
    width: 100%;
  }
  .float-link .link-btn .icon small {
    font-size: 100%;
  }
}

.cookies {
  display: none;
  width: 100%;
  position: fixed;
  background: white;
  border-top: 1px solid #aeadad;
  color: #333;
  font-size: 15px;
  font-size: 0.9375rem;
  font-size: 0.9375rem;
  text-align: center;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: 0.5rem 1rem;
}
.cookies > p {
  font-weight: 400;
  line-height: 2;
}
.cookies > p a {
  text-decoration: underline !important;
  color: blue;
  font-weight: 500;
}
.cookies > p .btn {
  color: #fff;
  display: inline;
  font-size: 13px;
  font-size: 0.8125rem;
  text-decoration: none !important;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.cookies.display {
  display: block;
  animation: cookies 1s 1;
}
@media (max-width: 810px) {
  .cookies {
    width: calc(100% - 1em);
    margin: 0.5em;
    border-top: none;
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.cookies-close {
  transition: all 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}
.article,
article {
  width: 100%;
  font-size: 100%;
}
.article iframe,
article iframe {
  width: 100%;
  height: 750px;
}
@media (max-width: 810px) {
  .article iframe,
article iframe {
    height: 350px;
  }
}

.in-box {
  overflow: hidden;
}

.js-open {
  display: block !important;
  margin-top: 5px;
}

.lh1-45 {
  line-height: 1.45;
}

.normal-content {
  overflow: hidden;
}
@media (min-width: 800px) and (max-width: 1600px) {
  .normal-content {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (min-width: 800px) and (max-width: 1100px) {
  .normal-content {
    padding-top: 1em;
  }
}
@media (max-width: 1024px) {
  .normal-content {
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .normal-content article {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.mainContent {
  padding-top: 1em;
  overflow: hidden;
}
.mainContent-wrap {
  padding-bottom: 2em;
}
@media (max-width: 1024px) {
  .mainContent-wrap {
    padding-top: 1em;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1025px) {
  .mainContent-info {
    width: 315px;
  }
  .mainContent-info ~ .mainContent-list {
    max-width: calc(100% - 315px);
    margin-left: auto;
    padding-left: 50px;
    padding-right: 1em;
  }
}
@media (min-width: 1101px) and (max-width: 1350px) {
  .mainContent-info {
    width: 270px;
  }
  .mainContent-info ~ .mainContent-list {
    max-width: calc(100% - 270px);
    padding-left: 1em;
  }
}
@media (max-width: 1024px) {
  .mainContent-info .link-wrap {
    display: none;
  }
}
.mainContent-list {
  max-width: 100%;
  flex: 1;
  padding-top: 1em;
  padding-bottom: 1em;
}
@media (min-width: 1025px) {
  .mainContent-list {
    padding-top: 0;
  }
}
@media (max-width: 1024px) {
  .mainContent-list {
    margin-left: 10px;
    margin-right: 10px;
    max-width: calc(100% - 20px);
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .mainContent {
    padding-left: 2em;
    padding-right: 2em;
  }
}
@media (max-width: 1024px) {
  .mainContent {
    flex-direction: column;
  }
}

.main-wrapper {
  max-width: 1450px;
  margin-top: 1em;
  margin-bottom: 2em;
}

.headingMain-title {
  position: relative;
  width: 100%;
  line-height: 1.3;
  margin-bottom: 0.25em;
  padding: 0.65em 1em 0.65em 0.5em;
}
.headingMain-title .en {
  font-size: 38px;
  font-size: 2.375rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  font-family: "Roboto";
}
@media (max-width: 1024px) {
  .headingMain-title .en {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.headingMain-title .big {
  font-size: 30px;
  font-size: 1.875rem;
}
@media (max-width: 1024px) {
  .headingMain-title .big {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}
.headingMain-title .styleLine-under, .headingMain-title .equip_num {
  margin-bottom: 0.2em;
  padding-bottom: 0.35em;
}
.headingMain-title .styleLine-under::before, .headingMain-title .equip_num::before {
  width: 30px;
  bottom: 7.5px;
  margin-left: -15px;
  background-color: #ae8c5d;
}
.headingMain-title .main {
  display: inline-block;
  font-size: 24px;
  font-size: 1.5rem;
  text-align: left;
  margin-bottom: 0;
  border: #656363 1px solid;
  border-radius: 25px;
  padding: 0.2em 1em 0.2em 2em;
  background-repeat: no-repeat;
  background-position: 15px;
  background-size: 19px;
  background-image: url("../images/dot-title.png");
}
@media (max-width: 1024px) {
  .headingMain-title .main {
    font-size: 20px;
    font-size: 1.25rem;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
  }
}

ul.link-wrap > li > a {
  position: relative;
  display: block;
  font-size: 18px;
  font-size: 1.125rem;
  color: #7c7c7c;
  line-height: 1.5;
  padding: 0.75em 0rem 1.1rem 0;
  text-align: center;
  border: #c3c1c1 1px solid;
}
ul.link-wrap > li > a p {
  margin-bottom: 0;
}
ul.link-wrap > li > a p::first-letter {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 100;
  padding-right: 2px;
}
ul.link-wrap > li > a::after {
  content: "";
  display: block;
  width: 30px;
  height: 4px;
  background-color: #e85465;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: 0.25s;
}
@media (min-width: 1025px) {
  ul.link-wrap > li > a {
    background: #e9e8e8;
  }
}
@media (max-width: 1024px) {
  ul.link-wrap > li > a {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
}
ul.link-wrap > li:hover a::after, ul.link-wrap > li.active a::after {
  opacity: 1;
}
@media (min-width: 1025px) {
  ul.link-wrap > li:hover a, ul.link-wrap > li.active a {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    color: #030303;
    border: none;
  }
}
@media (max-width: 1024px) {
  ul.link-wrap > li.active a {
    padding-bottom: 0.75em;
  }
}
ul.link-wrap > li > ol {
  display: none;
  padding-top: 10px;
}
ul.link-wrap > li > ol.active {
  display: block;
}
ul.link-wrap > li > ol > li {
  margin-bottom: 1px;
}
ul.link-wrap > li > ol > li > a {
  display: block;
  font-size: 20px;
  font-size: 1.25rem;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  position: relative;
  border-bottom: 1px solid #bfbfbf;
}
ul.link-wrap > li > ol > li > a::before {
  content: "\f067";
  font-family: FontAwesome;
  color: #404040;
  font-size: 13px;
  font-size: 0.8125rem;
  display: block;
  position: absolute;
  top: 1.05rem;
  right: 0;
}
ul.link-wrap > li > ol > li > a:hover, ul.link-wrap > li > ol > li > a.active {
  color: #ae8c5d;
}
ul.link-wrap > li > ol > li > a:hover, ul.link-wrap > li > ol > li > a:hover::before, ul.link-wrap > li > ol > li > a.active, ul.link-wrap > li > ol > li > a.active::before {
  font-weight: 600;
}
ul.link-wrap > li > ol > li > a:hover::before, ul.link-wrap > li > ol > li > a.active::before {
  content: "\f068";
}
ul.link-wrap > li > ol .second_menu {
  display: none;
  padding-top: 10px;
  padding-bottom: 15px;
}
ul.link-wrap > li > ol .second_menu.active {
  display: block;
}
ul.link-wrap > li > ol .second_menu > li {
  padding-left: 0;
  padding-bottom: 0;
}
ul.link-wrap > li > ol .second_menu > li > a {
  font-size: 18px;
  font-size: 1.125rem;
  color: #676767;
  padding-top: 7px;
  padding-bottom: 8px;
}
ul.link-wrap > li > ol .second_menu > li > a::before, ul.link-wrap > li > ol .second_menu > li > a::after {
  display: none;
}
ul.link-wrap > li > ol .second_menu > li > a:hover, ul.link-wrap > li > ol .second_menu > li > a:active {
  padding-left: 15px;
  color: #ae8c5d;
}
@media (min-width: 1025px) {
  ul.link-wrap > li {
    padding-left: 25px;
    padding-bottom: 1em;
  }
}
@media (min-width: 1101px) and (max-width: 1500px) {
  ul.link-wrap > li {
    padding-left: 0;
  }
}
@media (max-width: 1024px) {
  ul.link-wrap > li ~ li a {
    border-top: none;
  }
}
@media (max-width: 1024px) {
  ul.link-wrap {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.main-banner {
  overflow: hidden;
  z-index: 1;
}
.main-banner img {
  width: 100%;
}
.main-banner-text .title-main {
  justify-content: space-around;
}
.main-banner-text .title-btn {
  text-align: center;
}
@media (min-width: 1025px) {
  .main-banner-text {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .main-banner-text.right {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    right: 5em;
  }
  .main-banner-text.right .title-main {
    writing-mode: vertical-rl;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 2.5em;
  }
  .main-banner-text.right .title-main p {
    letter-spacing: 4px;
    margin: 0;
  }
  .main-banner-text.right .title-main span.text-main {
    display: inline-block;
    line-height: 100%;
    padding: 0.15em 0.2em 0 0;
  }
  .main-banner-text.right .title-btn {
    order: -1;
    writing-mode: vertical-rl;
    margin: 2em 2em 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-banner-text.right .title-btn .btn {
    font-size: 24px;
    font-size: 1.5rem;
    padding: 1.3em 0.55em 1em !important;
    border-color: #000;
    letter-spacing: 4px;
  }
  .main-banner-text.right .title-btn .btn:hover {
    border-color: #ae8c5d;
  }
  .main-banner-text.middle .title-main {
    line-height: 1.5;
  }
  .main-banner-text.middle .btn-wrap {
    margin-top: 3em;
  }
}
@media (max-width: 1024px) {
  .main-banner-text * {
    color: #000 !important;
  }
  .main-banner-text *::before {
    background-color: #000 !important;
  }
  .main-banner-text .btn {
    border-color: #000 !important;
  }
}

.text-mainRadius, .content-item, .img-mainRadius {
  border-radius: 15px !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.borderMain, .borderSmall {
  border: #212121 1px solid !important;
}
.borderIn {
  border: 1px solid #d2d2d2;
}
.borderMainCont {
  font-size: 17px;
  font-size: 1.0625rem;
  color: #fff;
  font-weight: 600;
  line-height: 1.5;
  background-color: #6ebe77;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
}
.borderNone {
  border: none !important;
}
.borderNoneTop {
  border-top: none !important;
}
.borderNoneBottom {
  border-bottom: none !important;
}
.borderSmall {
  background-color: #fff;
  border-radius: 30px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.5rem 2.5rem;
}
.border-inputMain {
  border-color: #ae8c5d;
}

.boxShadow {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.boxShadow_p20, .default-card-wrap .card:hover .card-img-top img {
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2) !important;
}

.boxShadow-main {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.font-lg {
  font-size: 52px;
  font-size: 3.25rem;
}
.font-md {
  font-size: 44px;
  font-size: 2.75rem;
}
.font-sm {
  font-size: 36px;
  font-size: 2.25rem;
}
.font-sms {
  font-size: 30px;
  font-size: 1.875rem;
}
.font-xs, .bg-item {
  font-size: 28px;
  font-size: 1.75rem;
}
.font-xsl {
  font-size: 26px;
  font-size: 1.625rem;
}
.font-xxs {
  font-size: 24px;
  font-size: 1.5rem;
}
.font-main {
  font-size: 22px;
  font-size: 1.375rem;
}
.font-defalut {
  font-size: 20px;
  font-size: 1.25rem;
}
.font-small {
  font-size: 18px;
  font-size: 1.125rem;
}
.font-small-xs {
  font-size: 16px;
  font-size: 1rem;
}
.font-small-xxs {
  font-size: 14px;
  font-size: 0.875rem;
}
.font-dot {
  font-size: 5px;
  font-size: 0.3125rem;
}

.fw-900, .Prod-conInfo-img .swiper-button-next::after, .Prod-conInfo-img .swiper-button-prev::after {
  font-weight: 900 !important;
}

.text-main {
  color: #ae8c5d !important;
}
.text-base {
  color: #212121 !important;
}
.text-underLine {
  text-decoration: underline;
}
.text-link {
  color: #1d2088;
}
.text-normal {
  color: #5d5d5e;
}

.bg-main {
  background-color: #ae8c5d;
}
.bg-intro {
  background-color: #eeeae9;
}
.bg-second {
  background-color: #eeeeee !important;
}
.bg-content {
  background-color: #ECECEC;
}
.bg-spec {
  background-color: #007ece;
}
.bg-item {
  background-color: #353535;
  color: #fff;
  padding: 0.5em 1.25em;
}
@media (max-width: 1024px) {
  .bg-item {
    font-weight: lighter;
    font-size: 20px !important;
    padding: 0.25em 0.75em;
  }
}
.bg-contentHelp {
  background-color: #e0e0e0;
}
.bg-contentEee {
  background-color: #eeeeee;
}

.flex-none {
  flex: none !important;
}
.flex-auto {
  flex: auto !important;
}
.flex-1 {
  flex: 1 !important;
}

.title-main {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 200;
  color: #7B7B7B;
  margin-bottom: 0.75em;
}
.title-mainSec {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  font-size: 22.5px;
  font-size: 1.40625rem;
}
@media (min-width: 1025px) {
  .title-mainSec {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
.title-mainHeading, .title-mainHeadingBlack {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 2em;
}
.title-mainHeading .title, .title-mainHeadingBlack .title {
  position: relative;
  display: inline-block;
  color: #353535;
  font-weight: 900;
  padding-bottom: 0.5em;
  font-size: 4.6875vw;
}
@media (min-width: 1025px) {
  .title-mainHeading .title, .title-mainHeadingBlack .title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.title-mainHeading .title::before, .title-mainHeadingBlack .title::before {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #ae8c5d;
}
@media (min-width: 1150px) and (max-width: 1500px) {
  .title-mainHeading, .title-mainHeadingBlack {
    padding-left: 3em;
  }
  .title-mainHeading .title, .title-mainHeadingBlack .title {
    font-size: 32px;
    font-size: 2rem;
  }
}
@media (max-width: 1024px) {
  .title-mainHeading, .title-mainHeadingBlack {
    margin-bottom: 1.5em;
  }
}
.title-mainHeadingPage {
  border-bottom: 1px solid #cecece;
  margin-bottom: 1.5em;
  padding-bottom: 0.75em;
}
.title-mainHeadingPage .title {
  color: #353535;
  font-size: 4.6875vw;
}
.title-mainHeadingPage .icon {
  vertical-align: top;
  margin: -1px 5px 0 7px;
}
@media (min-width: 1025px) {
  .title-mainHeadingPage {
    margin-bottom: 1.75em;
    padding-bottom: 15px;
  }
  .title-mainHeadingPage .title {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
.title-sub {
  font-size: 5.3125vw;
}
@media (min-width: 1025px) {
  .title-sub {
    font-size: 36px;
    font-size: 2.25rem;
  }
}
.title-default {
  font-size: 4.6875vw;
}
@media (min-width: 1025px) {
  .title-default {
    font-size: 32px;
    font-size: 2rem;
  }
}
.title-subcontent {
  font-size: 4.375vw;
}
@media (min-width: 1025px) {
  .title-subcontent {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
.title-mainHeadingBlack {
  padding-left: 0;
  border-bottom: none;
}
.title-mainHeadingBlack .title {
  padding-bottom: 0.2em;
}
.title-mainHeadingBlack .title::before {
  height: 3px;
  background-color: #000;
}

.heading-title {
  position: relative;
  width: 100%;
  color: #000;
  line-height: 1.3;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
}
.heading-title .icon {
  flex: none;
}
.heading-title .txt {
  flex: 1;
  padding-left: 15px;
}
.heading-title .title {
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: lighter;
}
.heading-title .titleSub {
  font-size: 17px;
  font-size: 1.0625rem;
  margin-bottom: 2px;
}
.heading-title.search .titleSub {
  color: #212121;
  font-size: 23px;
  font-size: 1.4375rem;
  padding-right: 10px;
}
@media (max-width: 1024px) {
  .heading-title {
    margin-bottom: 0.5em;
  }
}

.styleLine-rightMiddle, .equip_factory_head {
  position: relative;
  display: flex;
  align-items: center;
}
.styleLine-rightMiddle *, .equip_factory_head * {
  flex: none;
  margin-bottom: 0;
}
.styleLine-rightMiddle::after, .equip_factory_head::after {
  content: " ";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
}
.styleLine-under, .equip_num {
  position: relative;
  text-align: center;
  padding-bottom: 0.5em;
}
.styleLine-under::before, .equip_num::before {
  content: "";
  width: 60px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -30px;
  background-color: #ae8c5d;
}
.styleLine-under.main::before, .main.equip_num::before {
  width: 30px;
  height: 6px;
  margin-left: -15px;
}
.styleLine-verticle {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.styleLine-verticle::before {
  content: "";
  display: block;
  width: 1px;
  height: 30px;
  background-color: #000;
  margin-bottom: 1em;
}
.styleLine-verticle.white::before {
  background-color: #fff;
}
.styleLine-letTop {
  position: relative;
  padding-top: 0.5em;
  font-size: 22px;
  font-size: 1.375rem;
}
.styleLine-letTop * {
  margin-bottom: 0;
}
.styleLine-letTop::after {
  content: " ";
  display: block;
  width: 40px;
  height: 4px;
  background-color: #ae8c5d;
  position: absolute;
  top: 0;
  left: 0;
}
.styleLine-letVerticle {
  position: relative;
  padding-left: 15px;
}
.styleLine-letVerticle::before {
  content: "";
  display: block;
  width: 1px;
  height: 20px;
  background-color: #c9c9c9;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px;
}
.style_caretRight {
  position: relative;
  padding: 10px 0 10px 30px;
}
.style_caretRight:not(:last-child) {
  border-bottom: 1px dashed #fff;
}
.style_caretRight::before {
  content: "\f0da";
  font-family: FontAwesome;
  font-size: 1.25em;
  position: absolute;
  left: 0;
  top: 0.3em;
}

.block-title-box {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.25;
  margin-bottom: 1.25rem;
  padding-left: 0.5em;
  font-weight: 500;
  z-index: 2;
}
.block-title-box .txt {
  flex: none;
  margin-bottom: 0;
}
.block-title-box::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: #ae8c5d 2px solid;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: -15px;
}
.block-title-box::after {
  content: "";
  display: inline-block;
  width: 130px;
  height: 1px;
  background-color: #ae8c5d;
  margin-left: 0.5em;
}

.titleStyle {
  display: block;
  width: 100%;
}
.titleStyle::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #d6d6d6;
  position: absolute;
  top: 0px;
  z-index: 1;
}
.titleStyle::after {
  content: "";
  display: block;
  width: 30px;
  height: 21px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/icon_style.png");
  background-color: #fff;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
}
.titleStyle.gray::after {
  background-color: #eee;
}

.style__underLine {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #000;
}
.style__underLine__small {
  border-top: none;
  border-bottom-width: 2px;
  border-bottom-style: dotted;
}
.style__dot {
  position: relative;
  padding-left: 1rem;
  line-height: 1.5;
}
.style__dot:not(:last-of-type) {
  margin-bottom: 0.5em;
}
.style__dot::before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #615f5f;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 14px;
  transform: translateY(0);
}

.content-item {
  position: relative;
  justify-content: center;
  border: #dddddd 1px solid;
  overflow: hidden;
  transition: 0.25s;
  height: 0;
  padding: 1.65em 0.9em 46%;
}
.content-item .num {
  display: inline-block;
  font-size: 18px;
  font-size: 1.125rem;
  color: #353535;
  line-height: 1.25;
  background-color: #eeeeee;
  text-align: center;
  padding: 10px 15px;
  border-radius: 12px;
  position: absolute;
  left: -7px;
  top: -3px;
}
.content-item .info {
  text-align: center;
  color: #353535;
}
@media (max-width: 1024px) {
  .content-item .info {
    padding: 1em 0.5em;
  }
}
.content-item .title {
  font-weight: 900;
  font-size: 3.75vw;
}
@media (min-width: 1025px) {
  .content-item .title {
    font-size: 1.8947368421vw;
  }
}
@media (min-width: 1055px) and (max-width: 1100px) {
  .content-item .title {
    font-size: 1.7368421053vw;
  }
}
@media (min-width: 800px) and (max-width: 1050px) {
  .content-item .title {
    font-size: 2.34375vw;
  }
}
.content-item .txt {
  margin-bottom: 0;
  font-size: 3.125vw;
  line-height: 1.3;
}
@media (min-width: 1025px) {
  .content-item .txt {
    font-size: 0.8947368421vw;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .content-item .txt {
    font-size: 1.1578947368vw;
  }
}
@media (min-width: 1055px) and (max-width: 1100px) {
  .content-item .txt {
    font-size: 1.3157894737vw;
  }
}
@media (min-width: 800px) and (max-width: 1050px) {
  .content-item .txt {
    font-size: 2.5vw;
  }
}
@media (min-width: 1025px) {
  .content-item {
    background-color: #fff;
  }
}
@media (min-width: 800px) and (max-width: 1600px) {
  .content-item {
    height: auto;
    min-height: 8rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 1024px) {
  .content-item {
    height: auto;
    border-radius: 10px;
    padding: 0 !important;
  }
}
.content-link {
  position: relative;
  display: block;
  width: 100%;
}
@media (min-width: 1025px) {
  .content-link::before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    position: absolute;
    bottom: -17px;
    left: 50%;
    margin-left: -9px;
    transition: 0.25s;
    z-index: 2;
  }
  .content-link::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: #ae8c5d;
    border-radius: 50%;
    position: absolute;
    left: calc(100% + 1.1em);
    top: 50%;
    margin-top: -3.5px;
  }
}
@media (min-width: 1025px) and (max-width: 1024px) {
  .content-link::after {
    display: none;
  }
}
@media (min-width: 1025px) {
  .content-link:hover::before, .content-link.active::before {
    background-image: url("../images/content-link-active.png");
  }
  .content-link:hover .content-item, .content-link.active .content-item {
    border-color: #ae8c5d;
  }
  .content-link:hover .content-item .title, .content-link.active .content-item .title {
    color: #ae8c5d;
  }
}
.content-nav {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.content-nav-list {
  flex: 1;
}
.content-nav-list:last-child .content-link::after {
  display: none;
}
@media (min-width: 1025px) {
  .content-nav-list + .content-nav-list {
    padding-left: 2.5em;
  }
}
@media (max-width: 1024px) {
  .content-nav-list {
    flex: 0 0 calc(50% - 1em);
    margin: 0 0.5em 1.5em;
  }
}
.content-nav.small {
  justify-content: center;
}
.content-nav.small .content-nav-list {
  position: relative;
  flex: none;
}
.content-nav.small .content-link::after {
  left: calc(100% + 1em);
}
.content-nav.small .content-item {
  min-height: auto;
  height: auto;
  padding: 1.15em 2em;
}
.content-nav.small .content-item .title {
  font-size: 3.75vw;
  font-weight: 500;
}
@media (min-width: 1025px) {
  .content-nav.small .content-item .title {
    font-size: 1.1578947368vw;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .content-nav.small .content-item .title {
    font-size: 1.4736842105vw;
  }
}
@media (min-width: 1100px) and (max-width: 1300px) {
  .content-nav.small .content-item .title {
    font-size: 1.7368421053vw;
  }
}
@media (max-width: 1024px) {
  .content-nav.small .content-item .title br {
    display: none;
  }
}
.content-nav.small-noBorder .content-nav-list {
  flex: none;
}
.content-nav.small-noBorder .content-item {
  border: none;
}
.content-nav.small-noBorder .content-item .title {
  color: #606060;
  font-size: 3.4375vw;
}
@media (min-width: 1100px) {
  .content-nav.small-noBorder .content-item .title {
    font-size: 1.1578947368vw;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .content-nav.small-noBorder .content-item .title {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}
@media (min-width: 900px) and (max-width: 1100px) {
  .content-nav.small-noBorder .content-item .title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .content-nav.small-noBorder .content-item {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.content-nav.small-noBorder .content-link::before {
  width: 72px;
  height: 10px;
  bottom: 0;
  margin-left: -36px;
  transition: 0s;
}
.content-nav.small-noBorder .content-link::after {
  display: none;
}
.content-nav.small-noBorder .content-link:hover::before, .content-nav.small-noBorder .content-link.active::before {
  background-image: url("../images/content-line-active.png");
  bottom: 3px;
}
.content-nav.small-noBorder .content-link:hover::after, .content-nav.small-noBorder .content-link.active::after {
  display: block !important;
  width: 100%;
  height: 2px;
  border-radius: 0;
  left: 0;
  bottom: 10px;
  top: auto;
}
@media (max-width: 1024px) {
  .content-nav.small-noBorder .content-link:hover::before, .content-nav.small-noBorder .content-link.active::before {
    bottom: 0;
  }
  .content-nav.small-noBorder .content-link:hover::after, .content-nav.small-noBorder .content-link.active::after {
    bottom: 7px;
  }
}
@media (min-width: 1025px) {
  .content-nav.small.small-xs .content-item .title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.content-nav.small.default .content-nav-list {
  flex: 1 0 auto !important;
  max-width: 100% !important;
}
@media (min-width: 1025px) {
  .content-nav.small.default .content-item {
    min-height: 100px;
  }
}
@media (max-width: 1024px) {
  .content-nav.small.default .content-nav-list {
    margin: 5px;
  }
  .content-nav.small.default .content-item .info {
    padding: 0.5em 1em;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .content-nav.small .content-item {
    min-height: auto;
  }
}
@media (max-width: 1024px) {
  .content-nav.small {
    justify-content: space-around;
    margin-bottom: 1em;
  }
  .content-nav.small .content-link:hover::before, .content-nav.small .content-link:hover::after, .content-nav.small .content-link.active::before, .content-nav.small .content-link.active::after {
    display: none !important;
  }
  .content-nav.small .content-link:hover .title, .content-nav.small .content-link.active .title {
    color: #000;
  }
}
@media (max-width: 1024px) {
  .content-nav {
    justify-content: flex-start !important;
    margin-bottom: 0 !important;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .content-nav-list {
    flex: 0 0 calc(33.3333333333% - 4px) !important;
    max-width: calc(33.3333333333% - 4px) !important;
    margin: 0 2px 5px 2px;
    background: #eee;
  }
  .content-nav-list * {
    border: none;
  }
}
.content-nav-dropdown {
  min-width: 100vw;
  padding: 1em 0.25em;
  margin-bottom: 1.5em;
  background: #E5E5E5;
  position: absolute;
  left: 0.75em;
  top: 7em;
  z-index: 1;
}
.content-nav-dropdown::before {
  content: "";
  display: block;
  width: 1000%;
  height: 100%;
  background: #E5E5E5;
  position: absolute;
  left: -500%;
  top: 0;
  z-index: 0;
}
.content-nav-dropdown-container {
  position: relative;
  z-index: 1;
  width: 100%;
}
.content-nav-dropdown-container li {
  position: relative;
  padding: 0 2em;
}
.content-nav-dropdown-container li::after {
  content: "";
  width: 1px;
  height: 15px;
  background-color: #5F5F5F;
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -7.5px;
}
@media (max-width: 1024px) {
  .content-nav-dropdown-container li {
    padding-left: 1em;
    padding-right: 1em;
  }
  .content-nav-dropdown-container li::after {
    margin-top: -4.5px;
  }
}
.content-nav-dropdown-container a {
  font-size: 3.75vw;
  line-height: 1.5;
  color: #6c757d;
}
.content-nav-dropdown-container a:hover, .content-nav-dropdown-container a.active {
  color: #2B2B2B;
}
@media (min-width: 1025px) {
  .content-nav-dropdown-container a {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media (max-width: 1024px) {
  .content-nav-dropdown {
    position: relative;
    left: 0;
    top: 0;
    padding-top: 0.25em;
    padding-bottom: 0.5em;
    margin-bottom: 0;
  }
  .content-nav-dropdown-container a {
    font-size: 3.59375vw;
  }
}
.content-nav-dropdown.normal {
  position: relative;
  top: 0;
  left: 0;
  min-width: auto;
  overflow: hidden;
}
.content-nav-dropdown.normal .content-nav-dropdown-container {
  justify-content: center;
}
@media (max-width: 1024px) {
  .content-link.active::before, .content-link.active::after {
    display: none !important;
  }
}

.tag-content {
  text-align: center;
}
.tag-content a {
  border-radius: 25px;
  margin-left: 6px;
  margin-right: 6px;
}
.tag-content a.active {
  border-color: #ae8c5d;
  background-color: #ae8c5d;
  color: #fff;
}
@media (max-width: 1024px) {
  .tag-content a {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 3.125vw;
  }
}

.select_style::after {
  content: "\f0d7";
  font-family: "fontAwesome";
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  position: absolute;
  right: 8px;
  top: 1px;
}
.select_style select.form-control {
  border: 1px solid #e6eaea !important;
  background: #e6eaea;
  text-align: left;
  border-radius: 0;
  height: 34px !important;
  padding-top: 0;
  padding-bottom: 0;
}
.select_style select {
  width: 100%;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}
.select_style select,
.select_style input {
  border: none !important;
  height: 100% !important;
}
.select_style .form-control {
  border-radius: 0;
}
.select_style .disabled {
  background-color: #e9ecef;
}
.select_style .disabled select,
.select_style .disabled input {
  background: transparent;
}

.count_style {
  border: 1px solid #e6eaea !important;
  background: #e6eaea;
  text-align: left;
  border-radius: 0;
  display: flex;
  align-items: center;
  padding: 3px;
}
.count_style .item .form-control {
  height: auto;
  padding: 0;
  background-color: transparent;
  text-align: center;
}
.count_style .add,
.count_style .minus {
  width: 30px;
  flex: none;
  text-align: center;
  padding: 0;
}
.count_style .add.btn .fa,
.count_style .minus.btn .fa {
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0 !important;
  color: #212121;
}

.nav-tabs {
  --bs-nav-tabs-border-color: #989798;
}
.nav-tabs .nav-item ~ .nav-item {
  margin-left: 5px;
}
@media (min-width: 1025px) {
  .nav-tabs .nav-item {
    flex-basis: 270px;
  }
}
.nav-tabs .nav-link {
  width: 100%;
  color: var(--bs-nav-tabs-link-active-color);
  border-radius: 0;
  border-color: #c4c5c9;
  background-color: #fff;
  border-bottom-width: 0;
  margin-bottom: -2px;
  padding-top: 0.65em;
  padding-bottom: 0.65em;
}
.nav-tabs .nav-link.active {
  background-color: #eeeeee;
  border-color: #989798;
  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.15);
}
@media (min-width: 1025px) {
  .nav-tabs .nav-link {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
@media (max-width: 1024px) {
  .nav-tabs .nav-link {
    flex: 1;
    padding-left: 0.65em;
    padding-right: 0.65em;
  }
}
.nav-tabs + .tab-content {
  padding: 1em;
  background-color: #eeeeee;
}
@media (min-width: 1025px) {
  .nav-tabs + .tab-content {
    padding: 1em;
  }
}
@media (min-width: 1300px) {
  .nav-tabs + .tab-content {
    padding: 3em 6em;
  }
}

.breadcrum-wrap {
  border-top: #b5b5b5 1px solid;
  border-bottom: #b5b5b5 1px solid;
  margin-top: -6px;
  background: #e5e5e5;
  mix-blend-mode: multiply;
}
.breadcrum-wrap .breadcrumb {
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  color: #000000;
  background-color: transparent;
  margin-bottom: 0;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item {
  padding: 0.75em 1.25rem;
  border-right: #b5b5b5 1px solid;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item:first-child {
  border-left: #b5b5b5 1px solid;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item.active {
  position: relative;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item.active::after {
  content: "";
  display: inline-block;
  vertical-align: 0.255em;
  margin-left: 0.255em;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 0.3em;
  top: 50%;
  margin-top: -0.15em;
}
@media (min-width: 1025px) {
  .breadcrum-wrap .breadcrumb .breadcrumb-item.active {
    flex-basis: 230px;
  }
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  display: none;
}
@media (min-width: 1101px) and (max-width: 1350px) {
  .breadcrum-wrap .breadcrumb {
    padding-left: 3em;
  }
}
@media (min-width: 1025px) {
  .breadcrum-wrap .wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .breadcrum-wrap .wrapper .breadcrumb {
    flex: none;
  }
  .breadcrum-wrap .wrapper .search-input-group {
    flex-basis: 27em;
  }
}
@media (min-width: 1101px) and (max-width: 1350px) {
  .breadcrum-wrap .wrapper .search-input-group {
    padding-right: 3em;
  }
}
@media (max-width: 1024px) {
  .breadcrum-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.search-input-group .form-select,
.search-input-group .form-control,
.search-input-group .btn {
  border-width: 0 0 2px 0;
  border-bottom-color: #858485;
  outline: none;
  border-radius: 0;
}
.search-input-group .form-select {
  flex: none;
  width: 150px;
}
.search-input-group .form-control {
  margin-left: 10px !important;
  margin-right: 5px;
}
.search-input-group .btn {
  border: none;
  padding-left: 15px;
  padding-right: 15px;
}

.pagination-wrap {
  justify-content: center;
}
.pagination-wrap .pagination {
  justify-content: center;
}
.pagination-wrap .pagination .page-link {
  width: 25px;
  height: 25px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 25px;
  color: #6e6d6d;
  font-family: "Roboto";
  border-radius: 0;
  text-align: center;
  padding: 0;
  margin-right: 1rem;
  margin-left: 0.5rem;
  background-color: transparent;
  border: none;
}
.pagination-wrap .pagination .page-link:hover, .pagination-wrap .pagination .page-link.active {
  color: #000;
}
.pagination-wrap .pagination .page-link.prev, .pagination-wrap .pagination .page-link.next {
  background-color: #424242;
  border-color: #424242;
  color: #fff;
  border-radius: 4px;
  margin-left: 2em;
  margin-right: 2em;
}
.pagination-wrap .pagination .page-item {
  position: relative;
}
.pagination-wrap .pagination .page-item + .page-item::after {
  content: " / ";
  display: inline-block;
  padding: 0 5px;
  font-size: 12px;
  font-size: 0.75rem;
  color: #4D4D4D;
  position: absolute;
  left: -12px;
  top: 2px;
}
.pagination-wrap .pagination-select .select_style {
  width: 100%;
}
.pagination-wrap .pagination-select .select_style::after {
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1025px) {
  .pagination-wrap .pagination-select {
    width: 60px;
  }
}
@media (max-width: 1024px) {
  .pagination-wrap .pagination-select {
    display: flex;
    justify-content: center;
    width: 175px;
  }
}
.pagination-wrap .fa {
  font-size: 1.2em;
}
@media (max-width: 1024px) {
  .pagination-wrap {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

.tag-item, .table-overHidden::after {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  font-size: 14px;
  line-height: 1;
  padding: 6px;
  text-align: center;
  background-color: #eeeeee;
  color: #353535;
}
.tag-item.tag, .tag.table-overHidden::after {
  color: #4D4D4D;
  background-color: #e5e5e5;
  line-height: 25px;
  padding: 0.5em 1em;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-size: 0.875rem;
}
@media (min-width: 1025px) {
  .tag-item.tag, .tag.table-overHidden::after {
    font-size: 18px;
    font-size: 1.125rem;
    padding: 8px 24px;
  }
}
.tag-item.main, .main.table-overHidden::after {
  background-color: #ae8c5d;
  border-radius: 30px;
  color: #fff;
  padding: 0.5rem 1rem;
}
.tag-item.new, .new.table-overHidden::after {
  border-radius: 30px;
  background-color: #7eba2e;
  color: #fff;
  padding: 0.5rem 1rem;
}
.tag-item.new .txt, .new.table-overHidden::after .txt {
  color: #ffe42d;
  font-size: 14px;
  font-size: 0.875rem;
  padding-left: 5px;
}
.tag-item.default, .default.table-overHidden::after {
  border: #dbdbdb 1px solid;
  background-color: #fff;
  border-radius: 10px;
  color: #5f5f5f;
  font-size: 16px !important;
  padding: 6px 12px;
}
.tag-item.dark, .dark.table-overHidden::after {
  background-color: #353535;
  color: #fff;
}
.tag-item.white, .white.table-overHidden::after {
  display: inline-flex;
  align-items: center;
  border: #fff 1px solid;
  background-color: #fff;
  font-size: 16px !important;
  padding: 6px 12px;
  line-height: 1.5;
}
.tag-item.white .icon, .white.table-overHidden::after .icon {
  margin-right: 10px;
}

.swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-effects {
  position: relative;
  top: 0.5em;
}
.swiper-effects .swiper-pagination,
.swiper-effects .swiper-button-prev,
.swiper-effects .swiper-button-next {
  position: static;
  width: auto;
  margin: 0;
}
.swiper-effects .swiper-pagination {
  padding: 0 25px;
  font-family: "Roboto";
  color: #bbbbbb;
}
.swiper-effects .swiper-pagination .swiper-pagination-current {
  color: #212121;
}
.swiper-effects .swiper-button-prev::after,
.swiper-effects .swiper-button-next::after {
  font-size: 20px;
  font-size: 1.25rem;
  color: #212121;
}

.cataTxt {
  font-size: 16px;
  font-size: 1rem;
  color: #0c3766;
  line-height: 1.5;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 2.5px;
}
@media (max-width: 1024px) {
  .cataTxt {
    padding-top: 0;
    padding-bottom: 5px;
  }
}
.cataList {
  width: 100%;
  padding: 1em 1em 0.5em;
  z-index: 0;
  cursor: pointer;
}
@media (min-width: 1025px) {
  .cataList {
    margin-top: 0.5em;
  }
}
@media (max-width: 1024px) {
  .cataList {
    padding: 0.5em;
    padding-bottom: 0.25em;
  }
}
.cataList::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: #e8e8e8 1px solid;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.cataList-wrap:hover .cataList::before {
  box-shadow: 0 7px 10px -5px rgba(0, 0, 0, 0.1) !important;
}
.cataListImg {
  margin-bottom: 0.25rem;
  overflow: hidden;
  height: 9em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cataListImg img {
  max-height: 100%;
}
@media (max-width: 810px) {
  .cataListImg {
    height: 29vw;
    align-items: flex-start;
  }
}

.checkbox_style > input[type=checkbox] {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.checkbox_style > input + label {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 2px;
  margin-bottom: 5px;
  margin-right: 2px;
  margin-left: 0;
  padding-left: 1.75rem;
  cursor: pointer;
  border: none;
}
.checkbox_style > input + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  background-color: #E5E5E5;
}
.checkbox_style > input + label::after {
  content: "\f00c";
  font-family: FontAwesome;
  position: absolute;
  left: 2px;
  font-size: 1rem;
  color: #fff;
  opacity: 0;
}
.checkbox_style > input:checked + label::before {
  background-color: #ae8c5d;
  border-color: #ae8c5d;
}
.checkbox_style > input:checked + label::after {
  opacity: 1;
}
.checkbox_style p {
  margin-top: 10px;
}

.radio_style {
  margin-top: 10px !important;
}
.radio_style input[type=radio] {
  display: none;
}
.radio_style input + label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  padding-left: 30px;
  margin-bottom: 0;
}
.radio_style input + label::before, .radio_style input + label::after {
  content: "";
  display: inline-block;
  border-radius: 50%;
}
.radio_style input + label::before {
  width: 20px;
  height: 20px;
  border: #cecece 1px solid;
  background: #fff;
  position: absolute;
  top: 1px;
  left: 0;
}
.radio_style input + label::after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 5px;
  top: 6px;
}
.radio_style input:checked + label::after {
  background-color: #ae8c5d;
}
.form-group-list .item {
  flex: 1;
}
@media (max-width: 1024px) {
  .form-group-list .item {
    padding-right: 10px;
  }
}

.accordion .btn {
  position: relative;
  font-size: 4.6875vw;
  font-weight: 600;
  color: #353535;
  text-decoration: none !important;
  padding-left: 0;
}
.accordion .btn::after {
  content: "\f068";
  font-family: FontAwesome;
  position: absolute;
  right: 0;
  top: 10px;
}
.accordion .btn.collapsed::after {
  content: "\f067";
}
@media (min-width: 1025px) {
  .accordion .btn {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
.accordion .card {
  border: none;
}
.accordion .card > .card-header {
  background: transparent;
  padding-left: 0.3rem;
  padding-bottom: 0.3rem;
  margin-bottom: 0;
}
.accordion .card .card-body {
  line-height: 150%;
}
@media (min-width: 1025px) {
  .accordion .card .card-body {
    padding-left: 2.5em;
  }
}

@media (min-width: 1025px) {
  .pageBlog-content {
    padding-top: 4em;
  }
  .pageBlog-content .pageTitle {
    width: 26em;
    padding-left: 1em;
  }
  .pageBlog-content .pageIimg {
    flex: 1;
  }
  .pageBlog-content .btn {
    padding-left: 1.75em !important;
    padding-right: 1.75em !important;
    padding-bottom: 0.65em !important;
  }
  .pageBlog-content .ser-contentIntro-item-list {
    margin-bottom: 4em;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(odd) {
    margin-right: 0;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(odd) .pageTitle {
    padding-right: 5em;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(even) {
    margin-right: auto;
    margin-left: 0;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(even) .pageImg {
    order: -1;
  }
  .pageBlog-content .ser-contentIntro-item-list:nth-of-type(even) .pageTitle {
    padding-left: 5em;
  }
  .pageBlog-content .ser-contentIntro-item-list:last-of-type {
    margin-bottom: 0;
    padding-bottom: 4em;
  }
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .pageBlog-content .ser-contentIntro-item-list .pageTitle {
    padding-left: 2em !important;
    padding-right: 2em !important;
  }
}
@media (max-width: 1024px) {
  .pageBlog-content {
    padding: 1em 1em 0;
  }
  .pageBlog-content .pageImg {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.table-overHidden {
  display: block;
  width: 100%;
  overflow: hidden;
}
.table-overHidden::after {
  content: "若看不到完整內容，請向左滑動";
  padding: 0.75em 1em 1em;
  background-color: #000;
  border-radius: 20px;
  color: #fff;
  margin-bottom: 0.75em;
}
@media (min-width: 1025px) {
  .table-overHidden::after {
    display: none;
  }
}
@media (max-width: 1024px) {
  .table-overHidden {
    overflow-x: auto;
  }
}

.form-control,
.btn {
  border-radius: 0.5em;
}

.form-control {
  height: 50px;
}

.modal {
  text-align: center;
  padding-right: 0 !important;
}
.modal-open {
  padding-right: 0 !important;
}
@media (min-width: 1025px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
@media (min-width: 500px) {
  .modal-dialog {
    width: 100%;
    max-width: 44rem;
  }
}
@media (max-width: 1024px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }
  .modal.show {
    display: flex !important;
    align-items: center;
  }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
.modal-autoheight .modal-body {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px !important;
  max-height: 100vh !important;
}
@media (min-width: 500px) {
  .modal-autoheight .modal-body {
    max-height: calc(100vh - 50px) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight .modal-body {
    height: calc(90vh - 1.5rem) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight {
    transform: translateY(5%);
  }
}
.modal-member .modal-header,
.modal-member .modal-footer {
  border-top: none;
  border-bottom: none;
}
.modal-member .modal-header {
  position: static;
  margin-bottom: 1em;
}
.modal-member .modal-content {
  border-radius: 0;
}
@media (min-width: 1025px) {
  .modal-member .modal-content {
    padding: 4rem;
  }
}
.modal-member .modal-footer .btn {
  flex: 1;
  font-size: 14px;
  font-size: 0.875rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0.75em 2em;
}
.modal-member .modal-footer .btn-regis {
  font-size: 18px;
  font-size: 1.125rem;
}
@media (max-width: 640px) {
  .modal-member .modal-footer .btn {
    padding-left: 2.25em;
    padding-right: 2.25em;
  }
}
.modal-member .modal-footer .icon {
  position: relative;
  flex: none;
}
.modal-member .modal-footer .icon-fb, .modal-member .modal-footer .icon-user {
  top: 2px;
  left: -10px;
}
.modal-member .modal-footer .icon-right {
  right: -14px;
}
.modal-member .btn-close {
  position: absolute;
  right: 1.25em;
  top: 1.25em;
  font-size: 24px;
  font-size: 1.5rem;
}

.js-toggle-btn .btn {
  font-size: 16px;
  font-size: 1rem;
  border-radius: 20px;
  border: #ddd 1px solid;
}
.js-toggle-btn .btn i {
  color: #7d7d7d;
}

.btn-outline-white:hover, .btn-outline-default-main:hover, .btn-outline-default.mainAll:hover, .btn-outline-default.main:hover, .btn-outline-default:hover {
  background-color: #ae8c5d;
  border-color: #ae8c5d;
  color: #fff;
}
.btn-outline-white:hover img, .btn-outline-default-main:hover img, .btn-outline-default.mainAll:hover img, .btn-outline-default.main:hover img, .btn-outline-default:hover img {
  content: url("/assets/images/icon-link-small-active.png");
}

.btn-outline-white:hover, .btn-outline-default-main:hover, .btn-outline-default.mainAll:hover, .btn-outline-default.main:hover, .btn-outline-default:hover {
  background-color: #ae8c5d;
  border-color: #ae8c5d;
  color: #fff;
}

.btn {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 100;
  border-radius: 0px;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 0.5em;
}
.btn .fa {
  font-size: 0.6em;
  font-weight: 100;
  vertical-align: middle;
  margin-top: -3px;
  margin-left: 6px;
}
.btn .icon {
  vertical-align: top;
  margin-top: -2px;
  margin-right: 3px;
}
.btn-dark:focus {
  color: #fff !important;
}
.btn-main {
  border: 1px solid #ae8c5d;
  background-color: #ae8c5d;
  color: #fff;
}
.btn-main-normal {
  border: 1px solid #ae8c5d;
  background-color: #ae8c5d;
  color: #fff;
  padding: 0.75em 1.5em;
}
.btn-main-normal:hover, .btn-main-normal:focus {
  background-color: #fff !important;
  color: #ae8c5d !important;
}
.btn-main-default {
  background-color: #d8d6d6;
  border: #d8d6d6 2px solid;
}
.btn-main-default:hover {
  background-color: #212121;
  color: #d8d6d6;
}
.btn-outline-1 {
  border-style: solid !important;
  border-width: 1px !important;
}
.btn-outline-main {
  border: 2px solid #ae8c5d;
  color: #ae8c5d;
}
.btn-outline-main:hover, .btn-outline-main:focus {
  background-color: #ae8c5d !important;
  color: #fff !important;
}
.btn-outline-default {
  font-size: 18px;
  font-size: 1.125rem;
  border: #959595 1px solid;
  padding: 0.45em 1.25em !important;
  line-height: 1.5;
}
.btn-outline-default.main {
  border-color: #ae8c5d;
  color: #ae8c5d;
}
.btn-outline-default.mainAll {
  border-color: #ae8c5d;
  background-color: #ae8c5d;
  color: #fff;
}
.btn-outline-default-main {
  background-color: #ae8c5d;
  color: #fff;
}
.btn-outline-white {
  color: #fff;
  border: #fff 1px solid;
  padding: 0.35em 1.25em !important;
  line-height: 1.5;
}
.btn-outline-white:hover {
  background-color: #fff;
  color: #ae8c5d;
}
.btn-big {
  padding: 1.15rem 3em !important;
}
.btn-bigxf18 {
  font-size: 2.8125vw;
  padding: 0.4em 2em 0.45em !important;
}
@media (min-width: 1025px) {
  .btn-bigxf18 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.btn-bigxf24 {
  font-size: 3.75vw;
  padding: 0.4em 1.65em 0.45em !important;
}
@media (min-width: 1025px) {
  .btn-bigxf24 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
.btn-noRadius {
  border-radius: 0;
}
.btn-wrap {
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}

.js-toggle-btn .btn .icon {
  margin-top: 0;
}

.default-card-wrap.card-wrap {
  margin-top: 1.25em;
}
@media (max-width: 1024px) {
  .default-card-wrap.card-wrap {
    margin-top: 0.25em;
  }
}
.default-card-wrap .card {
  flex-grow: 1;
  border-radius: 0;
  border: none;
  background: transparent;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
}
@media (min-width: 1600px) {
  .default-card-wrap .card__2 {
    flex: 0 0 calc(50% - 2em) !important;
    max-width: calc(50% - 2em) !important;
  }
}
@media (min-width: 1025px) {
  .default-card-wrap .card__3 {
    flex: 0 0 calc(33.3333333333% - 2em) !important;
    max-width: calc(33.3333333333% - 2em) !important;
  }
}
@media (min-width: 1025px) {
  .default-card-wrap .card__4 {
    flex: 0 0 calc(25% - 2em) !important;
    max-width: calc(25% - 2em) !important;
  }
}
@media (max-width: 1024px) {
  .default-card-wrap .card {
    margin-left: 0.5em;
    margin-right: 0.5em;
    flex: 0 0 calc(50% - 1em);
    max-width: calc(50% - 1em);
  }
}
.default-card-wrap .card__full {
  flex: 0 0 calc(100% - 2em) !important;
  max-width: calc(100% - 2em) !important;
}
@media (min-width: 1025px) {
  .default-card-wrap .card__horizontal {
    flex-direction: row;
  }
}
.default-card-wrap .card-img-top {
  border-radius: 0;
}
.default-card-wrap .card-img-top img {
  display: block;
  width: 100%;
}
.default-card-wrap .card-body {
  background-color: transparent;
  padding: 1.25em 0 0;
}
@media (max-width: 1024px) {
  .default-card-wrap .card-body {
    padding-top: 0.25em;
  }
}
.default-card-wrap .card-title {
  font-size: 20px;
  font-size: 1.25rem;
  margin-top: 0.25em;
}
.default-card-wrap .card-btn .btn {
  border-radius: 6px;
}
.default-card-wrap .card-time {
  font-family: "Roboto";
}
.default-card-wrap .card-time .date {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 600;
}
.default-card-wrap .card-time .year {
  margin-top: 3px;
}
@media (max-width: 1024px) {
  .default-card-wrap .card {
    margin-bottom: 1em;
  }
  .default-card-wrap .card-time .date,
.default-card-wrap .card-time .year {
    margin-bottom: 0;
  }
  .default-card-wrap .card-caption {
    padding-top: 0.125em;
    padding-bottom: 0.25em;
  }
}

.form .form-control {
  border-radius: 0;
}
.form .form-control, .form .form-control:hover, .form .form-control:focus {
  border-width: 0 0 1px 0;
  border-style: solid;
  outline: none;
  box-shadow: none;
  background-color: #fff;
}
.form .form-control:hover, .form .form-control:focus {
  border-color: #000000;
}
.form-input-radio {
  height: 50px;
}
.form .radio_style {
  margin-top: 0 !important;
}
.form legend {
  line-height: 1.5;
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.form label {
  margin-bottom: 0;
}
.form-flex {
  display: flex;
  margin-bottom: 1.5em;
}
.form-flex > label {
  flex: none;
  width: 80px;
  color: #696969;
}
.form-login {
  display: flex;
  margin-bottom: 3em;
}
.form-login .form-group-sign {
  margin-left: 15px;
}
@media (max-width: 640px) {
  .form-login .form-group-sign {
    flex: none;
  }
}
.form-login .btn {
  height: 100%;
}
@media (max-width: 640px) {
  .form-login .btn {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.form-check {
  padding-left: 0;
}
.form-check-input[type=checkbox] {
  margin-top: 4px;
  margin-left: 0;
  margin-right: 10px;
}
.form-checkLogin {
  font-size: 14px;
  font-size: 0.875rem;
  color: #777777;
}
.form .divider {
  display: inline-block;
}

.formTxt-arial {
  font-family: Arial;
}

.formTxt-roboto {
  font-family: "Roboto";
}

.formTxt-bg {
  background: #f6f6f6;
}

.index-titleLogo {
  margin-top: 0;
  margin-bottom: 3.5em;
}
.index-titleLogo .img {
  position: absolute;
}
@media (min-width: 1025px) {
  .index-titleLogo .img {
    right: 1em;
  }
}
@media (max-width: 1024px) {
  .index-titleLogo {
    margin-top: 1.5em;
    margin-bottom: 6em;
  }
}
.index-content {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url("../images/bg_style.jpg");
  margin-top: 4.2em;
  padding-bottom: 2em;
}
.index-content-list {
  height: 100%;
}
@media (min-width: 1001px) and (max-width: 1500px) {
  .index-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 1024px) {
  .index-content .default-card-wrap.card-wrap {
    margin-bottom: -3em;
  }
}
.circle {
  width: 100%;
  padding-top: calc(100% + 1px);
  position: relative;
  border-radius: 50%;
  pointer-events: none;
  overflow: visible;
}
.circle * {
  position: relative;
  z-index: 2;
  color: #fff !important;
}
.circle::after {
  content: "";
  display: block;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  border: #fff 1px solid;
  border-radius: 50%;
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 1;
}
@media (max-width: 1024px) {
  .circle::after {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    left: 10px;
    top: 10px;
  }
}
.circleTxt {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 0.875;
  transform: translate(-50%, -50%);
}
.circleTxt .number {
  font-size: 4.4736842105vw;
  font-family: "Roboto";
}
.circleTxt .main {
  font-size: 1.1578947368vw;
  line-height: 1.25;
}
.circleTxt .title {
  font-size: 1.2631578947vw;
  font-weight: 900;
  color: #020202 !important;
}
.circleTxt .txt {
  font-size: 1.1578947368vw;
  font-weight: 900;
  color: #a8a8a8 !important;
}
@media (max-width: 1024px) {
  .circleTxt p {
    margin-bottom: 0.5em;
    line-height: 0.75;
  }
  .circleTxt .number {
    font-size: 10.9375vw;
    margin-bottom: 0.25em;
  }
  .circleTxt .main {
    font-size: 3.4375vw;
  }
  .circleTxt .title {
    font-size: 3.125vw;
  }
  .circleTxt .txt {
    font-size: 2.8125vw;
  }
}

.tech_platform_list {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 1024px) {
  .tech_platform_list {
    flex-wrap: wrap;
  }
}
.tech_platform_list li {
  width: 100%;
  max-width: 365px;
  position: relative;
  list-style: none;
  margin-left: 0.938em;
  margin-right: 0.938em;
}
.tech_platform_list li .circle::before {
  content: "";
  display: block;
  width: 88px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -3.15em;
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .tech_platform_list li .circle::before {
    width: 85px;
    left: -3.25em;
  }
}
.tech_platform_list li:first-child::after {
  content: "";
  display: block;
  width: 88px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -3.15em;
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .tech_platform_list li:first-child::after {
    left: -3.5em;
  }
}
.tech_platform_list li:first-child::before {
  content: "";
  display: block;
  width: 100%;
  max-width: 31.25em;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -19em;
}
.tech_platform_list li:last-child::before {
  content: "";
  display: block;
  width: 100%;
  max-width: 31.25em;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -21em;
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .tech_platform_list li:last-child::before {
    right: -18.85em;
  }
}
@media (max-width: 1024px) {
  .tech_platform_list li {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
@media (max-width: 810px) {
  .tech_platform_list li {
    width: 50%;
  }
  .tech_platform_list li:first-child::before, .tech_platform_list li:first-child::after, .tech_platform_list li:last-child::before, .tech_platform_list li:last-child::after {
    display: none;
  }
  .tech_platform_list li .circle::before {
    display: none;
  }
}
.tech_platform_list.tech-border .circle::after {
  border-color: #000;
  background-color: #fff;
}
.tech_platform_list.tech-border .circle * {
  color: #000 !important;
}
.tech_platform_list.tech-border li {
  max-width: 350px;
  margin-left: 0;
  margin-right: 0;
}
.tech_platform_list.tech-border li::before,
.tech_platform_list.tech-border li .circle::before {
  display: none;
}
.tech_platform_list.tech-border li::after {
  display: none;
}
.tech_platform_list.tech-border li:nth-child(odd) .circle::after {
  border-color: #ae8c5d;
}
.tech_platform_list.tech-border li:nth-child(odd) .circle * {
  color: #ae8c5d !important;
}

.animationItem {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
  transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}

.animationItem.animated {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.aboutTxt {
  font-size: 18px;
  font-size: 1.125rem;
  color: #1b1b1b;
  line-height: 1.85;
}
@media (min-width: 1025px) {
  .aboutTxt {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
.about-intro-txt, .about-intro-img {
  width: 100%;
}
.about-intro-txt {
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
}
@media (min-width: 1025px) {
  .about-intro {
    margin-top: 1em;
  }
  .about-intro-txt {
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    background-image: url("../images/about-introTxt-img.png");
    padding-left: 55px;
    padding-top: 150px;
    padding-bottom: 160px;
    padding-right: 200px;
    flex: 1;
    margin-right: -8em;
  }
  .about-intro-img {
    flex: 0 0 850px;
    max-width: 850px;
  }
}
@media (min-width: 1390px) and (max-width: 1500px) {
  .about-intro-txt {
    background-size: 800px;
    padding-top: 70px;
    padding-right: 160px;
  }
  .about-intro-img {
    flex: 0 0 745px;
    max-width: 745px;
  }
}
@media (min-width: 1001px) and (max-width: 1350px) {
  .about-intro-txt {
    padding-top: 2em;
    margin-right: -5em;
    padding-right: 6em;
    padding-bottom: 0;
  }
  .about-intro-img {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.about-intro-timeline {
  padding-bottom: 1.5em;
  overflow: hidden;
}
.about-intro-timeline::after {
  content: "";
  display: block;
  width: 20px;
  height: 21px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/timeLine-arrow-down.png");
  position: absolute;
  left: 50%;
  bottom: 0px;
  margin-left: -139px;
}
@media (max-width: 810px) {
  .about-intro-timeline::after {
    margin-left: -4.85em;
  }
}
.about-introSub {
  box-shadow: 0 0 20px 15px rgba(0, 0, 0, 0.1) !important;
  background-color: #fff;
  margin-top: 2.75em;
  padding: 1.5em;
}
.about-introSub-img {
  margin-right: 1em;
}
.about-introSub-txt {
  padding-top: 0.75em;
  flex: 1;
}
@media (min-width: 1025px) {
  .about-introSub {
    margin-bottom: 1.75em;
    padding: 3.25em 2.75em;
  }
  .about-introSub-img {
    margin-right: 30px;
  }
  .about-introSub-txt {
    padding-top: 6px;
  }
}
@media (max-width: 1024px) {
  .about-introSub-img {
    max-width: 2em;
  }
  .about-introSub-txt {
    padding-top: 0.5em;
  }
}

.timeline {
  list-style: none;
  padding: 0;
  margin: 0;
}
.timeline li {
  display: flex;
  align-items: stretch;
  color: #1b1b1b;
  padding-bottom: 2.5em;
}
.timeline-date {
  width: 100px;
  font-size: 32px;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
  font-family: "Roboto";
  font-weight: 900;
  margin-right: 25px;
}
@media (max-width: 810px) {
  .timeline-date {
    width: auto;
    font-size: 25px;
    font-size: 1.5625rem;
    margin-top: 4px;
    margin-right: 20px;
  }
}
.timeline-line {
  flex-basis: 260px;
  height: 1px;
  background-color: #7d7d7d;
  margin-top: 23px;
}
.timeline-line::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #7d7d7d;
  position: absolute;
  left: -8px;
  top: -3px;
}
@media (max-width: 810px) {
  .timeline-line {
    flex-basis: 2em;
    width: 100%;
  }
}
.timeline-content {
  flex: 1;
  padding-left: 50px;
}
.timeline-content::before {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fff;
  border: #000 3px solid;
  position: absolute;
  left: 0;
  top: 12px;
  z-index: 1;
}
.timeline-content::after {
  content: "";
  display: block;
  width: 1px;
  height: calc(100% + 2.5em);
  background-color: #d2d2d2;
  position: absolute;
  top: 15px;
  left: 10px;
  z-index: 0;
}
.timeline-content h2 {
  font-size: 20px;
  margin-bottom: 10px;
}
.timeline-content p {
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 0;
}
@media (min-width: 1025px) {
  .timeline-content p {
    line-height: 2.5;
  }
}
@media (max-width: 810px) {
  .timeline-content {
    padding-left: 2em;
    padding-top: 0.5em;
  }
}

.equip_wrap {
  margin-bottom: 3.5em;
}
@media (min-width: 1025px) {
  .equip_wrap {
    padding-left: 0.75em;
    padding-right: 0.75em;
  }
}
.equip_num {
  font-size: 24px;
  font-size: 1.5rem;
  color: #1b1b1b;
  font-family: "Open Sans";
  font-weight: 900 !important;
  text-align: left;
}
.equip_num::before {
  width: 100%;
  height: 3px;
  left: 0;
  margin-left: 0;
  background-color: #1b1b1b;
}
.equip_title {
  font-size: 20px;
  font-size: 1.25rem;
  margin: 1.25em 10px;
}
.equip_info {
  background-color: #eeeeee;
  padding: 1em 1.5em;
}
.equip_info_item {
  font-size: 16px;
  font-size: 1rem;
  color: #797878;
}
.equip_info_name {
  font-size: 18px;
  font-size: 1.125rem;
  color: #000000;
}
@media (min-width: 1025px) {
  .equip_info {
    display: flex;
  }
  .equip_info_item {
    flex: none;
    padding-top: 2px;
    padding-right: 1em;
  }
}
@media (min-width: 1025px) {
  .equip_factory_wrap {
    padding-left: 1.75em;
    padding-right: 1.75em;
  }
}
.equip_factory_head p {
  font-size: 21px;
  font-size: 1.3125rem;
  padding-right: 0.75em;
}
.equip_factory_head::after {
  height: 3px;
  background-color: #000;
}
.equip_factory_img {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: 1.25em;
  margin-right: 0;
  margin-top: 0.75em;
}
.equip_factory_img .img {
  text-align: center;
}
@media (min-width: 1025px) {
  .equip_factory_img .img {
    flex: 1;
    max-width: 178px;
  }
  .equip_factory_img .img + .img {
    margin-left: 15px;
  }
}
@media (max-width: 810px) {
  .equip_factory_img .img + .img {
    margin-top: 15px;
  }
}
@media (min-width: 1025px) {
  .equip_factory_col {
    flex-basis: 175px;
  }
}
@media (max-width: 1024px) {
  .equip_factory_col {
    width: 50%;
    flex: none;
  }
}
@media (min-width: 1025px) {
  .equip_factory_row_top .equip_factory_col:not(:last-child) {
    flex-basis: 35px;
  }
  .equip_factory_row_top .equip_factory_wrap {
    padding-left: 2em;
    padding-right: 0;
  }
}

.Prod-conInfo {
  padding-bottom: 1.5em;
}
@media (min-width: 1025px) {
  .Prod-conInfo {
    align-items: flex-start;
    padding-top: 3em;
    padding-bottom: 3em;
    margin-top: 1em;
  }
}
.Prod-conInfo-img {
  overflow: hidden;
}
@media (min-width: 1025px) {
  .Prod-conInfo-img {
    flex-basis: 855px;
    margin-left: -1.5rem !important;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .Prod-conInfo-img {
    flex-basis: 680px;
  }
}
@media (max-width: 1024px) {
  .Prod-conInfo-img {
    padding-top: 0.5em;
    padding-left: 1.75em;
    padding-right: 1.75em;
  }
}
.Prod-conInfo-img .swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.Prod-conInfo-img .swiper-slide {
  background-size: cover;
  background-position: center;
}
.Prod-conInfo-img .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
  width: 50px;
  color: #fff;
}
.Prod-conInfo-img .swiper-button-next::after, .Prod-conInfo-img .swiper-button-prev::after {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #000;
  font-size: 18px;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .Prod-conInfo-img .swiper-button-next {
    right: 0;
  }
  .Prod-conInfo-img .swiper-button-prev {
    left: 0;
  }
}
.Prod-conInfo-img .Prod_mySwiper2 {
  height: 80%;
  width: 100%;
  margin-bottom: 0.25em;
}
@media (min-width: 1025px) {
  .Prod-conInfo-img .Prod_mySwiper2 {
    margin-bottom: 15px;
  }
}
.Prod-conInfo-img .Prod_mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.Prod-conInfo-img .Prod_mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.Prod-conInfo-img .Prod_mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
@media (max-width: 1024px) {
  .Prod-conInfo-img .Prod_mySwiper .swiper-slide img {
    height: 150px;
  }
}
@media (max-width: 640px) {
  .Prod-conInfo-img .Prod_mySwiper .swiper-slide img {
    height: 70px;
  }
}
.Prod-conInfo-info {
  padding-left: 1.75em;
  padding-right: 1.75em;
  flex: 1;
}
@media (min-width: 1025px) {
  .Prod-conInfo-info {
    padding: 2.75em 0 0 3em;
  }
}
.Prod-conInfo-contact {
  border: 4px solid #dfdfdf;
}
.Prod-conInfo-contactTitle {
  background-color: #dfdfdf;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.Prod-conInfo-contactTitle .icon {
  margin-right: 10px;
}
@media (min-width: 1025px) {
  .Prod-conInfo-contactTitle {
    font-size: 20px;
    font-size: 1.25rem;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
  }
}
.Prod-conInfo-contactTitle, .Prod-conInfo-contactList {
  padding-left: 1.5em;
  padding-right: 1.5em;
}
@media (max-width: 1024px) {
  .Prod-conInfo-contactTitle, .Prod-conInfo-contactList {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}
.Prod-conInfo-contactList {
  background-color: #fff;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
@media (max-width: 1024px) {
  .Prod-conInfo-contactList {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}
.Prod-conInfo-contactList li ~ li {
  border-top: #e4e5eb 1px solid;
  margin-top: 12px;
  padding-top: 14px;
}
.Prod-conInfo-contactList-name {
  margin-right: 10px;
}
.Prod-conInfo-contactList-name .tag-item, .Prod-conInfo-contactList-name .table-overHidden::after {
  font-size: 16px;
  font-size: 1rem;
  padding: 10px;
}
@media (min-width: 1025px) {
  .Prod-conInfo-contactList-name .tag-item, .Prod-conInfo-contactList-name .table-overHidden::after {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .Prod-conInfo-contactList-tel {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
@media (min-width: 1025px) {
  .Prod-conInfo-contact {
    margin-top: 3em;
  }
}
.Prod-item {
  color: #585858;
}
@media (min-width: 1025px) {
  .Prod-item {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.Prod-heading {
  line-height: 1.5;
}
@media (min-width: 1025px) {
  .Prod-heading {
    font-size: 36px;
    font-size: 2.25rem;
  }
}
.Prod-unreset {
  border-top: 1px dotted #898989;
  border-bottom: 1px dotted #898989;
  padding-top: 1em;
  padding-bottom: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.Prod-unreset dl {
  margin-bottom: 0;
}
.Prod-unreset dl dt {
  width: 185px;
  font-weight: normal;
  padding-left: 1.2em;
  padding-right: 1.2em;
}
.Prod-unreset dl dd {
  flex: 1;
  color: #707070;
}
@media (min-width: 1025px) {
  .Prod-unreset dl dd {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
@media (max-width: 810px) {
  .Prod-unreset {
    padding-top: 0.5em;
    padding-bottom: 0em;
    margin-bottom: 1.5em;
  }
}
@media (max-width: 810px) and (max-width: 810px) {
  .Prod-unreset dl {
    flex-direction: column;
    margin-bottom: 0.25em;
  }
  .Prod-unreset dl dt,
.Prod-unreset dl dd {
    padding-left: 0.2em;
  }
}
.Prod-photo-small {
  padding-left: 1.5em;
  padding-right: 1.5em;
}
@media (min-width: 1025px) {
  .Prod-photo-small {
    padding-left: 3em;
    padding-right: 3em;
    margin-top: 15px;
  }
}

.item-table {
  border-radius: 10px;
  border: #6b6b6b 1px solid;
  background-color: #fff;
  padding: 6px 10px 3px;
}
.item-table table {
  border: none;
}
.item-table table > :not(caption) > * > * {
  background-color: #fff;
  padding: 0.75rem 1rem;
}
.item-table table thead th {
  font-size: 18px;
  font-size: 1.125rem;
  color: #475c86;
  border-bottom-width: 0 !important;
}
.item-table table tbody td {
  font-size: 20px;
  font-size: 1.25rem;
  border-width: 1px;
  border-color: #b4c0d6;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}
.item-table table tbody tr td:first-child {
  border-left-width: 0;
}
.item-table table tbody tr td:last-child {
  border-right-width: 0;
}
.item-table table tbody:nth-child(even) td {
  background-color: #e9edf4;
}
.item-table table tbody:nth-child(odd)::before, .item-table table tbody:nth-child(odd)::after {
  content: ".";
  display: block;
  line-height: 1em;
  color: white;
}
.item-table table tbody:nth-child(odd) td {
  background-color: #eeeeee;
}
@media (max-width: 1024px) {
  .item-table {
    width: 1140px;
    margin-bottom: 1em;
  }
}

.table-main tbody td {
  background-color: #e9edf4 !important;
}
.table-secondary tbody td {
  background-color: #eeeeee !important;
}

.contact-block {
  position: relative;
}
.contact-form {
  border: #b5b5b5 1px solid;
  background-color: #fff;
  padding: 1em;
}
.contact-form .btn-wrap {
  margin-top: 2em;
}
.contact-form .btn-wrap li {
  display: flex;
  justify-content: center;
  width: 100%;
}
.contact-form .btn-wrap .btn {
  line-height: 2;
}
@media (max-width: 640px) {
  .contact-form .btn-wrap {
    flex-direction: column;
  }
  .contact-form .btn-wrap li {
    margin-bottom: 10px;
  }
}
@media (min-width: 1025px) {
  .contact-form {
    padding: 50px 60px 35px;
    max-width: 62.5%;
  }
  .contact-form legend {
    margin-bottom: 2.75rem;
  }
}
@media (max-width: 1024px) {
  .contact-form {
    margin-bottom: 1.5rem;
    max-width: 100%;
  }
}
.contact-info .icon {
  margin-right: 20px;
}
.contact-info .txt {
  width: 100%;
  font-size: 22px;
  font-size: 1.375rem;
}
.contact-info .txt-item {
  color: #484747;
  padding-right: 10px;
}
.contact-info .txt p {
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .contact-info .txt {
    font-size: 18px;
    font-size: 1.125rem;
    margin-top: 1rem;
  }
}
.contact-info-list {
  display: flex;
  border-bottom: 1px solid #b2b1b1;
  padding-bottom: 1.25em;
}
.contact-info-list ~ li {
  margin-top: 1.5em;
}
@media (min-width: 1025px) {
  .contact-info-list {
    align-items: center;
  }
}
@media (min-width: 1025px) {
  .contact-info {
    flex: 1;
    padding-left: 80px;
  }
}
@media (max-width: 1024px) {
  .contact {
    display: block;
  }
}